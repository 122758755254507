import { Button, DatePicker, Input, Modal, Popconfirm, Table, Tag } from "antd";
import Axios from "axios";
import moment from "moment";
import React from "react";
// import ReactChipInput from "react-chip-input";
// import { CsvToHtmlTable } from "react-csv-to-table";
import { connect } from "react-redux";
import {
  startAddMembers,
  startRefreshMembers,
} from "../../../redux/actions/adminData";
import {
  fbGetFormLayout,
  fbGetPendingMembers,
  getAllPackages,
  getMyId,
} from "../../../services/firebaseService/endPoints/admin/members";
import {
  editMemberInLocal,
  getFilteredMembersAsCSV,
  getFilteredMembersFromLocal,
} from "../../../services/indexedDb/members";
import MemberTable from "./Table";
import converter from "json-2-csv";
import { db } from "../../../services/firebaseService/connection";
import RegistrationCode from "../groupConfig/RegistrationCode";
import GroupRegFormConfiguration from "./GroupRegFormConfiguration";
import Onboard from "./Onboard";
// import Promote from "../promote/promote";
import { FInput } from "../../commons/formFields/FInput";
import { FSelect } from "../../commons/formFields/FSelect";
import FanPermission from "../groupConfig/FanPermission";
import {
  getCurrencyUnicode,
  switchCollapseHorizontal,
} from "../../../helperFunctions/util";
import Promotev2 from "./Promotev2";
import {
  doc,
  collection,
  getDocs,
  getDoc,
  updateDoc,
} from "@firebase/firestore";
import MembershipPackageTable from "./MembershipPackageTable";
import logger from "../../../utils/logger";

const pageSize = 100;

const DEFAULT_SORT_ORDER = [
  "admin",
  "leader",
  "capo",
  "ticket-scanner",
  "member",
];

const STATUS_FILTERS = [
  "Members (all)",
  "Members (on Chant)",
  "Members (not on Chant)",
  "Members (on auto-renewal)",
  "Leaders",
  "Fans (non-members)",
  "Inactive",
  "Locked/Archived",
];

const OTHERS_FILTERS = [
  "Official Club Member",
  "Season Ticket Holder",
  "Volunteer (yes)",
  "Volunteer (no)",
  "Receive emails (yes)",
  "Receive emails (no)",
  "Donated",
];

class ListMember extends React.Component {
  constructor(props) {
    super(props);
    this.csvFile = null;
    this.state = {
      csvFileText: "",
      search: "",
      isLoading: true,
      myDocId: "",
      myName: "",
      chips: [],
      tableData: [],
      pagination: {
        // position: ['bottomRight', 'topRight']
      },
      newMembersDays: "",
      newMembersTotal: "",
      sorter: { field: "", order: "" },
      downloading: false,
      pendingEmails: [],
      currentUserRole: "",
      packages: [],
      showAddMemberButton: false,
      renderTable: true,
      runningService: false,
      formLayout: {},
      idToAccept: props.match.params.id,
      showAddMemberModal: !!props.match.params.id,
      registrationData: {},
      groupToAccept: props.match.params.group,
      filters: {},
    };
  }

  handleTableChange = (pagination, filters, sorter) => {
    if (!(filters && filters.name && filters.name.length > 0)) {
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState(
        {
          pagination: pager?.current ? pager : null,
          sorter,
          renderTable: false,
        },
        () => {
          this.setState({ renderTable: true });
        }
      );
      this.fetchFilteredMembers((pager.current - 1) * pageSize, sorter, true);
      document.querySelector(".ant-table-body").scrollTo(0, 0);
    }
  };

  fetchFilteredMembers = (skip, sorter, hasTableChange = false) => {
    let orderBy, order;
    if (sorter) {
      orderBy = sorter.field;
      order = sorter.order;
    }
    getFilteredMembersFromLocal(
      this.props.currentGroup,
      this.state.filters,
      skip,
      this.state.packages,
      this.props.adminData.data.requireMembershipPackage,
      orderBy,
      order === "ascend" ? "next" : "prev"
    ).then((data) => {
      if (!orderBy || orderBy === "name") {
        if (!order || order === "ascend") {
          data.sort((a, b) => a.name.localeCompare(b.name));
        } else if (order === "descend") {
          data.sort((a, b) => b.name.localeCompare(a.name));
        }
      }

      if (orderBy === "memberSince") {
        if (!order || order === "ascend") {
          data.sort((a, b) =>
            String(a?.memberSince).localeCompare(String(b?.memberSince))
          );
        } else if (order === "descend") {
          data.sort((a, b) =>
            String(b?.memberSince).localeCompare(String(a?.memberSince))
          );
        }
      }

      const packageIdMap = this.state.packages.reduce((acc, item) => {
        acc[item.id] = item.name;
        return acc;
      }, {});

      if (orderBy === "package") {
        if (!order || order === "ascend") {
          data.sort((a, b) => {
            // Access the package names using package IDs from data
            return packageIdMap[a?.package]?.localeCompare(
              packageIdMap[b?.package] || ""
            );
          });
        } else if (order === "descend") {
          data.sort((a, b) => {
            return packageIdMap[b?.package]?.localeCompare(
              packageIdMap[a?.package] || ""
            );
          });
        }
      }

      let result = {
        tableData: data,
        isLoading: false,
      };
      if (hasTableChange) {
        result.newMembersTotal = data.length;
      } else {
        if (data.length > pageSize) {
          let pagination = { ...this.state.pagination };
          pagination.defaultPageSize = pageSize;
          pagination.pageSize = pageSize;
          pagination.position = "bottom";
          result.pagination = pagination;
        } else {
          result.pagination = false;
        }
      }
      this.setState(result, () => {});
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({
      search: "",
      tableData: [],
      pagination: {},
      sorter: { field: "", order: "" },
    });
  };

  editMember = (content) => {
    editMemberInLocal(content, this.props.currentGroup).then(() => {
      this.fetchFilteredMembers((this.state.pagination.current - 1) * pageSize);
    });
  };

  handleMemberRefresh = () => {
    // if (this.props.match.params.list !== "new") {
    //   this.props.dispatch(startRefreshMembers(this.props.currentGroup));
    //   this.props.dispatch(startAddMembers(this.props.currentGroup));
    // } else {
    // this.setState({ refreshing: true })
    this.setState({ isLoading: true, tableData: [], pagination: {} });

    this.props.dispatch(startRefreshMembers(this.props.currentGroup));
    this.props.dispatch(startAddMembers(this.props.currentGroup));
    this.getPendingMembers();
    this.getPackages();

    // }
  };

  changeTab = (e) => {
    this.props.history.replace(`/admin/members/${e.target.name}`);
  };

  goToPath = (path) => {
    this.props.history.replace(path);
  };

  uploadCSV = (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      let file = e.target.files[0];
      this.csvFile = file;
      let reader = new FileReader();
      reader.readAsText(this.csvFile);
      reader.onload = () => {
        this.setState({ csvFileText: reader.result });
      };
    } else {
      this.csvFile = null;
      this.setState({ csvFileText: "" });
    }
  };

  uploadCSVToFirebase = async () => {
    if (this.csvFile === null && this.state.chips.length === 0) {
      alert(
        "Please click on choose file button or add emails in text entry box"
      );
    } else {
      this.setState({
        runningService: true,
      });
      if (this.csvFile === null) {
        var url = `https://us-central1-chant2019.cloudfunctions.net/addEmailsForRegistartion`;
        var functionBody = {};
        functionBody.emailsList = this.state.chips;
        functionBody.groupDocId = this.props.currentGroup;
        await Axios.post(url, functionBody)
          .then((_) => {
            alert("Successfully updated");
            this.csvFile = null;
            document.getElementById("uploadCSVFileId").value = "";
            this.setState({ csvFileText: "", chips: [] });
          })
          .catch((err) => {
            logger.error(`Error in updating ${err} `);
            alert("Something went wrong, try again after sometime");
          });
      } else {
        const lines = this.state.csvFileText.split("\n");
        var emails = [];
        var temp = lines[0].split(",");
        if (temp[0].trim().toLowerCase() === "email") {
          for (let i = 0; i < lines.length; i++) {
            const currentline = lines[i].split(",");
            const headers = ["Email"];
            const columns = [0];
            var email = "";
            for (let j = 0; j < headers.length; j++) {
              email = currentline[columns[j]];
            }
            if (email.trim().toLowerCase() !== "")
              emails.push(email.trim().toLowerCase());
          }
          emails.shift();

          var functionUrl = `https://us-central1-chant2019.cloudfunctions.net/addEmailsForRegistartion`;
          var body = {};
          body.emailsList = emails;
          body.groupDocId = this.props.currentGroup;
          await Axios.post(functionUrl, body)
            .then((_) => {
              alert("Successfully updated");
              this.csvFile = null;
              document.getElementById("uploadCSVFileId").value = "";
              this.setState({ csvFileText: "", chips: [] });
            })
            .catch((err) => {
              logger.error(`Error in updating ${err} `);
              alert("Something went wrong, try again after sometime");
            });
        } else {
          alert("CSV file is not in the same structure as mentioned above.");
        }
      }
      this.setState({
        runningService: false,
      });
      this.getPendingMembers();
    }
  };

  handleDownloadCsv = () => {
    this.setState({ downloading: true });

    let customFields = Object.keys(
      this.state.formLayout.customFieldDetails || {}
    ).map((key) => {
      let fieldTitle =
        (this.state.formLayout.customFieldDetails[key] || {}).fieldTitle || "";
      return {
        fieldName: key,
        fieldTitle,
      };
    });

    let groupCurrencyISO = this.props.adminData.data.paymentDetails
      ? this.props.adminData.data.paymentDetails.currency || "USD"
      : "USD";
    let groupCurrency = getCurrencyUnicode(groupCurrencyISO);

    let promise = getFilteredMembersAsCSV(
      this.props.currentGroup,
      this.state.filters,
      this.state.packages,
      this.props.adminData.data.requireMembershipPackage,
      customFields,
      groupCurrency
    );
    promise
      .then((csv) => {
        const a = window.document.createElement("a");
        a.href = window.URL.createObjectURL(
          new Blob([csv, "Text"], { type: "text/csv" })
        );
        a.download = "members.csv";

        // Append anchor to body.
        document.body.appendChild(a);
        a.click();

        // Remove anchor from body
        document.body.removeChild(a);
        this.setState({ downloading: false });
      })
      .catch((err) => {
        logger.error(err);
        this.setState({ downloading: false });
      });
  };

  addChip = (value) => {
    const chips = this.state.chips.slice();
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var validEmail = re.test(String(value).toLowerCase());
    if (validEmail) {
      chips.push(value.toLowerCase());
      this.setState({ chips });
    } else {
      alert("Invalid Email");
    }
  };
  removeChip = (index) => {
    const chips = this.state.chips.slice();
    chips.splice(index, 1);
    this.setState({ chips });
  };

  copyList = () => {
    const temp = document.createElement("textarea");
    let value = this.state.pendingEmails
      .map((obj, index) => {
        if (index === this.state.pendingEmails.length - 1) {
          return obj.email;
        }
        return `${obj.email}\r\n`;
      })
      .toString()
      .replaceAll(",", "");
    temp.value = value;
    document.body.appendChild(temp);
    temp.select();
    document.execCommand("copy");
    document.body.removeChild(temp);
  };

  handleFilterChange = (e) => {
    let targetName = e.target.name;
    let filters = this.state.filters;
    if (e.target.value) {
      filters[e.target.name] = e.target.value;
    } else {
      if (filters[e.target.name]) {
        delete filters[e.target.name];
      }
    }
    this.setState(
      {
        filters: filters,
      },
      () => {
        if (targetName !== "search") {
          this.fetchFilteredMembers();
        }
      }
    );
  };

  // handlePaste = (e) => {
  //   e.preventDefault();
  //   let paste = (e.clipboardData || window.clipboardData).getData('text');
  //   if (paste && e.target.nodeName.toLowerCase() === 'input') {
  //     let emails = paste.split(/[\r\n]/g);
  //     emails.forEach(value => {
  //       if (value) {
  //         this.addChip(value);
  //       }
  //     })
  //   }
  // }

  downloadPendingList = () => {
    let groupName = this.props.adminData.data.groupName.replaceAll(" ", "_");

    converter.json2csv(
      JSON.parse(JSON.stringify(this.state.pendingEmails)),
      (err, csv) => {
        if (err) {
          logger.error(err);
          return;
        }
        var link = document.createElement("a");
        link.setAttribute(
          "href",
          window.URL.createObjectURL(
            new Blob([csv, "Text"], { type: "text/csv" })
          )
        );
        link.setAttribute("download", `${groupName}_pending_list.csv`);
        document.body.appendChild(link);

        link.click();
        document.body.removeChild(link);
      }
    );
  };

  deletePendingList = async () => {
    let docRef = doc(db, "invite_emails", this.props.currentGroup);

    let emailsInUserDetails = await getDocs(
      collection(db, docRef.path, "userDetails")
    ).then((snap) => {
      let emails = [];
      snap.docs.forEach((doc) => {
        let user = doc.data() || {};
        emails.push(user.email ?? "");
      });

      emails = emails.filter((email) => email);
      return emails;
    });

    getDoc(docRef)
      .then((doc) => {
        if (doc.exists()) {
          emailsInUserDetails = Array.from(
            new Set(
              emailsInUserDetails.filter((email) => email && email.trim())
            )
          );
          updateDoc(docRef, { emails: emailsInUserDetails }).then(() => {
            this.getPendingMembers();
          });
        }
      })
      .then(() => {
        logger.log("emails deleted");
      });
  };

  addMember = () => {
    let url =
      "https://us-central1-chant2019.cloudfunctions.net/stripe2/writeDataToFirebase";
    let metadata = this.state.registrationData.data;
    let groupId = this.state.groupToAccept;
    let reqBody = {
      metadata: metadata,
      groupId: groupId,
    };
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqBody),
    })
      .then(() => {
        this.setState({
          idToAccept: "",
          showAddMemberModal: false,
          registrationData: {},
        });
      })
      .catch((err) => logger.error("Failed to add member: ", err));
  };

  onPackageModalClose = () => {
    getAllPackages(this.props.currentGroup).then((packageData) => {
      this.setState({ packages: packageData, loading: false });
    });
  };

  render() {
    const columns = [
      {
        title: "Pre-registered emails",
        key: "email",
        className: "header-white",
        dataIndex: "email",
        render: (email) => <span style={{ fontWeight: "bold" }}>{email}</span>,
      },
      {
        title: "Package",
        key: "package",
        dataIndex: "package",
      },
    ];

    let allMembersData = [...this.state.tableData];

    let pagination = this.state.pagination
      ? { ...this.state.pagination }
      : false;

    if (pagination) {
      pagination.total = allMembersData.length;
      pagination.newMembersTotal = allMembersData.length;
    }

    // if (!(this.state.sorter && this.state.sorter.field)) {
    //   allMembersData.sort((row1, row2) => {
    //     return row1.name.localeCompare(row2.name);
    //   });
    // }

    let allPackages = this.state.packages || [];

    let activePackages = allPackages
      .filter((pack) => pack && pack.status === "active")
      .map((pack) => ({ name: pack.name, id: pack.id }));

    if (!this.props.match.params.list) return <></>;

    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }
    return (
      <>
        {this.state.idToAccept && (
          <Modal
            title={<h5>Confirm Registration Request</h5>}
            width={700}
            centered
            visible={this.state.showAddMemberModal}
            onOk={() => {
              this.addMember();
            }}
            onCancel={() => {
              this.setState({
                showAddMemberModal: false,
              });
            }}
          >
            <div className="row">
              <div className="col-md-6">Name:</div>
              <div className="col-md-6">{this.state.registrationData.name}</div>
            </div>

            <div className="row">
              <div className="col-md-6">Email:</div>
              <div className="col-md-6">
                {this.state.registrationData.email}
              </div>
            </div>
          </Modal>
        )}
        <div
          className="mx-auto"
          style={{
            width: "95%",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {this.props.match.params.list === "all" ||
          this.props.match.params.list === "inactive" ? (
            <div className="d-flex mb-2" style={{ justifyContent: "flex-end" }}>
              <Tag
                color={
                  (this.state.search && "magenta") ||
                  (this.props.match.params.list === "new" && "green")
                }
              >
                {this.state.search &&
                  `${this.state.tableData.length} match found for search '${
                    this.state.search
                  }' ${
                    this.props.match.params.list === "new"
                      ? "from all members"
                      : ""
                  } `}
                {/* {!this.state.search &&
                      this.props.match.params.list !== "new" &&
                      `${this.state.pagination.total} records`} */}
                {(this.props.match.params.list === "all" ||
                  this.props.match.params.list === "inactive") &&
                  !this.state.search &&
                  `${allMembersData.length} members`}
              </Tag>
            </div>
          ) : (
            <div className="d-flex" style={{ height: 30 }}></div>
          )}
          <div>
            <div style={{ position: "relative" }}>
              <ul
                className="nav nav-tabs"
                style={{
                  fontSize: "15px",
                  color: "#ffffff",
                  flexWrap: "nowrap",
                  whiteSpace: "nowrap",
                }}
              >
                {this.props.match.params.list === "all" && (
                  <li
                    className="nav-item"
                    onClick={this.changeTab}
                    style={{
                      borderBottom: `4px solid ${primaryColor}`,
                    }}
                  >
                    <a
                      name="all"
                      style={{
                        height: "100%",
                        fontWeight: "bold",
                        fontSize: "22px",
                      }}
                    >
                      Members
                    </a>
                  </li>
                )}

                {(this.props.match.params.list === "csv" ||
                  this.props.match.params.list === "pending" ||
                  this.props.match.params.list === "fanSettings") && (
                  <>
                    <ResponsiveMobileTab
                      menuItems={[
                        { key: "csv", label: "Onboard" },
                        { key: "pending", label: "Pending" },
                        { key: "fanSettings", label: "Fan Permissions" },
                      ]}
                      param={this.props.match.params.list}
                      primaryColor={primaryColor}
                      changeTab={this.changeTab}
                    />
                  </>
                )}
                {this.props.match.params.list === "promote" && (
                  <li
                    className="nav-item"
                    onClick={this.changeTab}
                    style={{
                      borderBottom: `4px solid ${primaryColor}`,
                    }}
                  >
                    <a
                      name="promote"
                      style={{
                        height: "100%",
                        fontWeight: "bold",
                        fontSize: "22px",
                      }}
                    >
                      Promote
                    </a>
                  </li>
                )}
                {`${this.props.match.params.list}`.includes(
                  "groupRegConfig"
                ) && (
                  <>
                    <ResponsiveMobileTab
                      menuItems={[
                        { key: "groupRegConfigPackage", label: "Packages" },
                        { key: "groupRegConfigSetup", label: "Setup" },
                      ]}
                      param={this.props.match.params.list}
                      primaryColor={primaryColor}
                      changeTab={this.changeTab}
                    />
                  </>
                )}
                {/* <li className="nav-item" onClick={this.changeTab}>
                <a
                  name="merchandise"
                  className={
                    this.props.match.params.list === "merchandise"
                      ? "nav-link active a-color-primary"
                      : "nav-link a-color-secondary"
                  }
                >
                  Shipping Summary
                </a>
              </li> */}
                <li
                  className="ml-auto align-items-center collapsible-horizontal tab-menu"
                  id="member-utilities"
                >
                  {(this.props.match.params.list === "all" ||
                    this.props.match.params.list === "pending") && (
                    <a
                      class="icon"
                      style={{ textAlign: "end", marginTop: 5, fontSize: 18 }}
                      onClick={() =>
                        switchCollapseHorizontal("member-utilities")
                      }
                    >
                      <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </a>
                  )}
                  {!this.state.search && (
                    <>
                      {this.props.match.params.list === "all" && (
                        <>
                          {this.state.showAddMemberButton && (
                            <Popconfirm
                              title="This allows you to register a member while bypassing the payment process. Continue?"
                              okText="Yes"
                              cancelText="No"
                              onConfirm={() => {
                                // this.props.history.push(`/admin/members/${this.props.adminData.data.groupName.replace(/ /g, '').replaceAll('#', '%23').toLowerCase()}/add`);
                                let url = `https://group.chant.fan/${this.props.adminData.data.groupName.replace(/ /g, "").replaceAll("#", "%23").toLowerCase()}/join/addMember?id=${getMyId()}`;
                                window.open(url);
                              }}
                              onCancel={() => {}}
                            >
                              <Button
                                style={{
                                  backgroundColor: "#999999",
                                  padding: "5px 10px",
                                  color: "#ffffff",
                                }}
                                className="mr-1"
                                onClick={() => {}}
                              >
                                Add Member
                              </Button>
                            </Popconfirm>
                          )}
                        </>
                      )}
                      {this.props.match.params.list === "pending" && (
                        <div className="d-flex ml-auto">
                          <div
                            className="d-flex"
                            style={{ alignItems: "center" }}
                          >
                            <Popconfirm
                              title="Download pending list as CSV?"
                              onConfirm={this.downloadPendingList}
                              onCancel={() => {}}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button
                                type={"primary"}
                                style={{
                                  backgroundColor: "#999999",
                                  padding: "5px 10px",
                                  color: "#ffffff",
                                }}
                                className="mr-1"
                              >
                                Download List
                              </Button>
                            </Popconfirm>
                            <Popconfirm
                              title={
                                <div style={{ width: 400 }}>
                                  <p>Delete pre-registered emails?</p>

                                  <p>
                                    Note: This will not delete records for
                                    members who have purchased a membership
                                    package but not yet joined Chant.
                                  </p>

                                  <p>
                                    Only email addresses manually added by the
                                    admin (via Onboard) are deleted.
                                  </p>
                                </div>
                              }
                              onConfirm={this.deletePendingList}
                              onCancel={() => {}}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button
                                type={"primary"}
                                className="mr-1"
                                style={{
                                  backgroundColor: "#999999",
                                  padding: "5px 10px",
                                  color: "#ffffff",
                                }}
                              >
                                Delete List
                              </Button>
                            </Popconfirm>
                            <Tag color="green">{`${this.state.pendingEmails.length} pending`}</Tag>
                          </div>
                        </div>
                      )}
                      {this.props.match.params.list === "merchandise" && (
                        <>
                          <Popconfirm
                            title={"Download all members list?"}
                            onConfirm={() => {
                              this.downloadMerchCSV();
                            }}
                            onCancel={() => {}}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button
                              loading={this.state.downloading}
                              style={{
                                backgroundColor: "#999999",
                                padding: "5px 10px",
                                color: "#ffffff",
                              }}
                              className="mr-1"
                            >
                              Download List
                            </Button>
                          </Popconfirm>
                        </>
                      )}
                      {(this.props.match.params.list === "all" ||
                        this.props.match.params.list === "fans" ||
                        this.props.match.params.list === "inactive") && (
                        <>
                          <Popconfirm
                            title={"Download all members list?"}
                            onConfirm={() => {
                              this.handleDownloadCsv();
                            }}
                            onCancel={() => {}}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button
                              loading={this.state.downloading}
                              style={{
                                backgroundColor: "#999999",
                                padding: "5px 10px",
                                color: "#ffffff",
                              }}
                              className="mr-1"
                            >
                              Download List
                            </Button>
                          </Popconfirm>
                          <Popconfirm
                            title={
                              this.props.match.params.list === "new"
                                ? "Check for new members?"
                                : "Refresh member list?"
                            }
                            onConfirm={() => {
                              this.handleMemberRefresh();
                            }}
                            onCancel={() => {}}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button
                              className="default-text-color"
                              style={{
                                backgroundColor: primaryColor,
                                padding: "5px 10px",
                              }}
                              loading={this.state.refreshing}
                            >
                              Refresh
                            </Button>
                          </Popconfirm>
                        </>
                      )}
                    </>
                  )}

                  <div className="show-for-mobile">
                    <div className="menu-list">
                      {!this.state.search && (
                        <>
                          {this.props.match.params.list === "all" && (
                            <>
                              {this.state.showAddMemberButton && (
                                <Popconfirm
                                  title="This allows you to register a member while bypassing the payment process. Continue?"
                                  okText="Yes"
                                  cancelText="No"
                                  onConfirm={() => {
                                    // this.props.history.push(`/admin/members/${this.props.adminData.data.groupName.replace(/ /g, '').replaceAll('#', '%23').toLowerCase()}/add`);
                                    let url = `https://group.chant.fan/${this.props.adminData.data.groupName.replace(/ /g, "").replaceAll("#", "%23").toLowerCase()}/join/addMember?id=${getMyId()}`;
                                    window.open(url);
                                  }}
                                  onCancel={() => {}}
                                >
                                  <div
                                    style={{
                                      backgroundColor: "#ffffff",
                                      padding: "5px 10px",
                                      color: "#000000",
                                    }}
                                    onClick={() => {}}
                                  >
                                    Add Member
                                  </div>
                                </Popconfirm>
                              )}
                            </>
                          )}
                          {this.props.match.params.list === "pending" && (
                            <div className="d-flex ml-auto">
                              <div
                                className="d-flex"
                                style={{ alignItems: "center" }}
                              >
                                <Popconfirm
                                  title="Download pending list as CSV?"
                                  onConfirm={this.downloadPendingList}
                                  onCancel={() => {}}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <div
                                    type={"primary"}
                                    style={{
                                      backgroundColor: "#ffffff",
                                      padding: "5px 10px",
                                      color: "#000000",
                                    }}
                                  >
                                    Download List
                                  </div>
                                </Popconfirm>
                                <Popconfirm
                                  title={
                                    <div style={{ width: 400 }}>
                                      <p>Delete pre-registered emails?</p>

                                      <p>
                                        Note: This will not delete records for
                                        members who have purchased a membership
                                        package but not yet joined Chant.
                                      </p>

                                      <p>
                                        Only email addresses manually added by
                                        the admin (via Onboard) are deleted.
                                      </p>
                                    </div>
                                  }
                                  onConfirm={this.deletePendingList}
                                  onCancel={() => {}}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <div
                                    type={"primary"}
                                    style={{
                                      backgroundColor: "#ffffff",
                                      padding: "5px 10px",
                                      color: "#000000",
                                    }}
                                  >
                                    Delete List
                                  </div>
                                </Popconfirm>
                                <Tag color="green">{`${this.state.pendingEmails.length} pending`}</Tag>
                              </div>
                            </div>
                          )}
                          {this.props.match.params.list === "merchandise" && (
                            <>
                              <Popconfirm
                                title={"Download all members list?"}
                                onConfirm={() => {
                                  this.downloadMerchCSV();
                                }}
                                onCancel={() => {}}
                                okText="Yes"
                                cancelText="No"
                              >
                                <div
                                  loading={this.state.downloading}
                                  style={{
                                    backgroundColor: "#ffffff",
                                    padding: "5px 10px",
                                    color: "#000000",
                                  }}
                                >
                                  Download List
                                </div>
                              </Popconfirm>
                            </>
                          )}
                          {(this.props.match.params.list === "all" ||
                            this.props.match.params.list === "fans" ||
                            this.props.match.params.list === "inactive") && (
                            <>
                              <Popconfirm
                                title={"Download all members list?"}
                                onConfirm={() => {
                                  this.handleDownloadCsv();
                                }}
                                onCancel={() => {}}
                                okText="Yes"
                                cancelText="No"
                              >
                                <div
                                  loading={this.state.downloading}
                                  style={{
                                    backgroundColor: "#ffffff",
                                    padding: "5px 10px",
                                    color: "#000000",
                                  }}
                                >
                                  Download List
                                </div>
                              </Popconfirm>
                              <Popconfirm
                                title={
                                  this.props.match.params.list === "new"
                                    ? "Check for new members?"
                                    : "Refresh member list?"
                                }
                                onConfirm={() => {
                                  this.handleMemberRefresh();
                                }}
                                onCancel={() => {}}
                                okText="Yes"
                                cancelText="No"
                              >
                                <div
                                  style={{
                                    backgroundColor: "#ffffff",
                                    padding: "5px 10px",
                                    color: "#000000",
                                  }}
                                  loading={this.state.refreshing}
                                >
                                  Refresh
                                </div>
                              </Popconfirm>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  {/* <div className="d-flex justify-content-end mx-2">
                  <i>
                    {(this.props.match.params.list === "all" || this.props.match.params.list === "fans")
                      ? `Last refreshed today at: ${this.props.adminData.members.date &&
                      moment
                        .unix(this.props.adminData.members.date)
                        .format("HH:mm")
                      } `
                      : ""}
                  </i>
                </div> */}
                </li>
              </ul>
            </div>
          </div>

          <div className="mt-0" id="scroll-div" style={{ height: "100%" }}>
            {/* {(this.props.match.params.list === "all") &&
              <p>
                All profiles appear here unless your group uses the Fan (non-member) role or uses 'Membership Packages'. If group requires membership packages anyone with an expired package will appear in an Inactive tab.
              </p>
            }
            {(this.props.match.params.list === "fans") &&
              <p>
                The Fan (non-member) role is set up in Onboard (Option #4) with permissions set in Settings / Fan Permissions. This is used to keep 'non-members' engaged with restricted app access. Fans can be upgraded to members by changing their role or having them buy a membership package (if your group uses packages).
              </p>
            }
            {(this.props.match.params.list === "inactive") &&
              <p>
                Profiles appear in Inactive if your group uses membership packages and you have checked 'Require membership package?' in the setup of the 'Member Registration Form'. They will not have app access. Renewal of their member package provides app access (and moves the profile to the members tab).
              </p>
            } */}

            {this.props.match.params.list === "all" && (
              <div className="col filters-bottom-0">
                <div
                  className="row mt-2 collapsible-horizontal collapsile-filter"
                  id="member-filter"
                >
                  <a
                    class="icon"
                    onClick={() => switchCollapseHorizontal("member-filter")}
                  >
                    Filters
                  </a>
                  <div className="col-lg-3">
                    <FInput
                      value={this.state.filters.search}
                      onChange={this.handleFilterChange}
                      name="search"
                      display="row"
                      placeholder="Search name / email"
                      tabIndex="2"
                      style={{
                        backgroundColor: "transparent",
                        border: "0px none transparent",
                        borderBottom: "2px solid white",
                        color: "white",
                        borderRadius: 0,
                        fontSize: 16,
                      }}
                      className="placeholder-white"
                      onBlur={() => {
                        this.fetchFilteredMembers();
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          this.fetchFilteredMembers();
                        }
                      }}
                    />
                  </div>
                  <div
                    className="ml-2"
                    style={{ flex: 1, padding: "0px 15px" }}
                  >
                    <FSelect
                      value={this.state.filters.status}
                      onChange={this.handleFilterChange}
                      dataList={STATUS_FILTERS}
                      dataOnlyValue={true}
                      style={{
                        backgroundColor: "transparent",
                        border: "0px none transparent",
                        borderBottom: "2px solid white",
                        color: "white",
                        borderRadius: 0,
                        fontSize: 16,
                      }}
                      name="status"
                      display="row"
                      placeholder="Status (All)"
                      tabIndex="2"
                    />
                  </div>
                  {activePackages && activePackages.length > 0 ? (
                    <div
                      className="ml-2"
                      style={{ flex: 1, padding: "0px 15px" }}
                    >
                      <FSelect
                        value={this.state.filters.package}
                        onChange={this.handleFilterChange}
                        dataList={activePackages}
                        style={{
                          backgroundColor: "transparent",
                          border: "0px none transparent",
                          borderBottom: "2px solid white",
                          color: "white",
                          borderRadius: 0,
                          fontSize: 16,
                        }}
                        dataKey="id"
                        dataValue="name"
                        name="package"
                        display="row"
                        placeholder="Package"
                        tabIndex="2"
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  <div
                    className="ml-2"
                    style={{ flex: 1, padding: "0px 15px" }}
                  >
                    <FSelect
                      value={this.state.filters.chapter}
                      onChange={this.handleFilterChange}
                      dataList={this.state.formLayout.chapters || []}
                      dataOnlyValue={true}
                      style={{
                        backgroundColor: "transparent",
                        border: "0px none transparent",
                        borderBottom: "2px solid white",
                        color: "white",
                        borderRadius: 0,
                        fontSize: 16,
                      }}
                      name="chapter"
                      display="row"
                      placeholder="Chapter"
                      tabIndex="2"
                    />
                  </div>
                  <div
                    className="ml-2"
                    style={{ flex: 1, padding: "0px 15px" }}
                  >
                    <FSelect
                      value={this.state.filters.other}
                      onChange={this.handleFilterChange}
                      dataList={OTHERS_FILTERS}
                      dataOnlyValue={true}
                      style={{
                        backgroundColor: "transparent",
                        border: "0px none transparent",
                        borderBottom: "2px solid white",
                        color: "white",
                        borderRadius: 0,
                        fontSize: 16,
                      }}
                      name="other"
                      display="row"
                      placeholder="Other"
                      tabIndex="2"
                    />
                  </div>
                  <div
                    className="ml-2"
                    style={{ flex: 1, padding: "0px 15px" }}
                  >
                    <div className="row">
                      <DatePicker
                        tabIndex="2"
                        value={
                          this.state.filters.joinedSince
                            ? moment.unix(this.state.filters.joinedSince)
                            : null
                        }
                        onChange={(value) => {
                          if (value) {
                            let timestamp = value.unix();
                            this.handleFilterChange({
                              target: {
                                name: "joinedSince",
                                value: timestamp,
                              },
                            });
                          } else {
                            this.handleFilterChange({
                              target: {
                                name: "joinedSince",
                                value: 0,
                              },
                            });
                          }
                        }}
                        placeholder="Joined Since"
                        className="form-control form-control-sm col"
                        size={10}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.props.match.params.list === "all" &&
              this.state.filters.status === "Locked/Archived" && (
                <div className="mt-3">
                  <p>
                    Locked/Archived records are not included in ‘Status (All)'
                    or any other Members table view.
                  </p>
                </div>
              )}
            {this.props.match.params.list === "all" &&
              this.state.renderTable && (
                <MemberTable
                  groupData={this.props.adminData.data}
                  myDocId={this.state.myDocId}
                  myName={this.state.myName}
                  editMemberInLocalDb={this.editMember}
                  tableData={allMembersData}
                  onChange={this.handleTableChange}
                  packages={this.state.packages || []}
                  formLayout={this.state.formLayout}
                  pagination={pagination}
                  loading={this.state.isLoading}
                  handleSearch={this.handleSearch}
                  handleReset={this.handleReset}
                  sorter={this.state.sorter && this.state.sorter}
                  currentUserRole={this.state.currentUserRole}
                />
              )}
            {this.props.match.params.list === "csv" && (
              <Onboard
                chips={this.state.chips}
                addChip={this.addChip}
                removeChip={this.removeChip}
                downloading={this.state.downloading}
                uploadCSVToFirebase={this.uploadCSVToFirebase}
                uploadCSV={this.uploadCSV}
                groupName={this.props.adminData.data.groupName}
                currentGroup={this.props.currentGroup}
                registrationCode={this.props.adminData.data.registrationCode}
                registrationLink={this.props.adminData.data.registrationLink}
                backgroundColor={primaryColor}
                csvFile={this.csvFile}
                runningService={this.state.runningService}
                fanJoin={this.props.adminData.data.fanJoin}
                regCodeRole={this.props.adminData.data.regCodeRole}
                groupLogo={this.props.adminData.data.configuration.logo}
                isFanPermissionSet={
                  this.props.adminData.data.groupPermission &&
                  typeof this.props.adminData.data.groupPermission === "object"
                }
              />
              // <div style={{ fontFamily: "Arial", marginLeft: "14px", fontSize: "14px" }}>
              //   <br />
              //   <p style={{ fontSize: "16px", color: "#ff4040" }}>
              //     Chant does not email members. After pre-registration you should notify members to join Chant.
              //   </p>
              //   <p style={{ fontSize: "16px", color: "#555555" }}>
              //     Sample note: You have been pre-registered for the new [group name] app. Download Chant from the <a href="https://apps.apple.com/us/app/chant/id1477159385#?platform=iphone">App Store</a> or <a href="https://play.google.com/store/apps/details?id=com.geekyants.services.chant">Google Play</a> and Register. ¡Vamos!
              //   </p>

              //   <br /><br /><br />

              //   <p style={{ fontSize: "18px", color: "#ff4040", fontWeight: "bold" }}>
              //     Option #1 (Individual)
              //   </p>
              //   <p style={{ fontSize: "16px", color: "#555555" }}>
              //     To pre-register an individual or small number of members, type
              //     each email address below. Press ‘enter’ on your keyboard after
              //     each email. When done adding emails click ‘Upload’.
              //   </p>
              //   <div>
              //     <ReactChipInput
              //       classes="react-chip-input__chip-bg-color react-chip-input__chip-hover-bg-color react-chip-input__chip-hover-border-color react-chip-input__box-shadow-color"
              //       chips={this.state.chips}
              //       onSubmit={(value) => this.addChip(value)}
              //       onRemove={(index) => this.removeChip(index)}
              //     />
              //   </div>

              //   {this.state.chips.length !== 0 && <div><br /> <Button
              //     style={{ display: "inline-block" }}
              //     loading={this.state.downloading}
              //     type={"danger"}
              //     className="mr-1"
              //     size="default"
              //     onClick={this.uploadCSVToFirebase}
              //   >
              //     Upload
              //   </Button></div>}
              //   <br /><br /><br />
              //   <p style={{ fontSize: "18px", color: "#ff4040", fontWeight: "bold" }}>
              //     Option #2 (Bulk)
              //   </p>
              //   <p style={{ fontSize: "16px", color: "#555555" }}>
              //     To pre-register a large group of members, upload a CSV file with their email address. You can upload 500 emails at a time.
              //   </p>
              //   <p style={{ fontStyle: "italic", color: "#777777" }}>
              //     <span style={{ fontsize: "50px", color: "#FF0000" }}>*</span>{" "}
              //     The CSV file should only have a single column of emails in
              //     column 1 and include the word ‘Email’ as the column header in
              //     row 1.
              //   </p>
              //   <img alt="Bulk Registration" src={require("../../../images/bulk.png")} />
              //   <p style={{ fontStyle: "italic", color: "#777777" }}> You can create a CSV from a spreadsheet by going to
              //     ‘File / Save As’.
              //   </p>
              //   <br />
              //   <div style={{ display: "inline-block" }}>
              //     <form action="." enctype="multipart/form-data">
              //       <input id="uploadCSVFileId" type="file" accept=".csv" style={{ color: "#FF0000" }} onChange={this.uploadCSV} />
              //     </form>
              //   </div>
              //   {this.csvFile !== null && <Button
              //     style={{ display: "inline-block", marginBottom: "10px" }}
              //     loading={this.state.downloading}
              //     type={"danger"}
              //     className="mr-1"
              //     size="default"
              //     onClick={this.uploadCSVToFirebase}
              //   >
              //     Upload
              //   </Button>}
              //   <br></br>
              //   {this.state.csvFileText !== "" &&
              //     this.props.match.params.list === "csv" && (
              //       <CsvToHtmlTable
              //         data={this.state.csvFileText}
              //         csvDelimiter=","
              //         tableClassName="table table-striped table-hover"
              //       />
              //     )}
              //   <br /> <br />

              //   {/* <p style={{ fontSize: "18px", color: "#ff4040", fontWeight: "bold" }}>
              //     Option #3 (Manual Registration with code)
              //   </p>
              //   <p
              //     style={{
              //       fontSize: "16px",
              //       color: "#555555",
              //     }}
              //   >
              //     If you do not intend to pre-register members or would like to
              //     open up your group to the public you can share the
              //     registration code found under Settings / Registration code.
              //     These users will be required to register manually on
              //     <a href="https://www.chant.fan" rel="noopener noreferrer"> www.chant.fan </a>
              //     and pay a fee.
              //   </p> */}
              // </div>
            )}

            {this.props.match.params.list === "pending" && (
              <div>
                <div className="d-flex" style={{ marginTop: 20 }}>
                  <p>
                    Pending shows the emails uploaded (Onboard Option #1-2) and
                    from the registration form (Onboard Option #3) who have not
                    yet registered in Chant.
                  </p>
                </div>
                <br />
                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <Table
                    columns={columns}
                    className={`table-backdrop`}
                    dataSource={this.state.pendingEmails}
                    bodyStyle={{ backgroundColor: "#ffffff" }}
                    bordered
                    pagination={false}
                  />
                </div>
              </div>
            )}
            {this.props.match.params.list === "registrationCode" && (
              <>
                <div style={{ marginTop: 20 }}>
                  <p>
                    If you prefer not to pre-register members, simply share this
                    code to be used during member registration.
                  </p>

                  <p>
                    Consider sharing it in your Facebook Group, via email and at
                    events.
                  </p>

                  <p>
                    If you feel the code has been shared inappropriately you can
                    generate a new code.
                  </p>

                  {/* <a href='#' onClick={(e) => {
                    e.preventDefault();
                    this.props.history.push('/admin/registrationCode')
                  }}
                  >Get a code</a> */}
                  <RegistrationCode showText={false} />
                </div>
              </>
            )}
            {this.props.match.params.list === "groupRegConfigPackage" && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ marginTop: 40 }}>
                  <p>
                    Sell membership packages, merchandise (add through the
                    store) and collect donations. Stripe account required.
                    Active’ packages will appear on the web registration page.
                  </p>
                  <p>
                    Members who purchase a package are automatically
                    pre-registered for Chant.
                  </p>
                  <p>
                    You can offer packages and donations subscriptions
                    (auto-renewal). Members can cancel subscriptions from their
                    profile (in Chant) or via the Cancel link from the
                    subscription email receipt.
                  </p>
                  <p>
                    If package includes merchandise (scarf, shirt, etc) first
                    add product to Store then select item in the ‘add package’
                    form below. Orders will then appear in the ‘Store: Orders’
                    report to track fulfillment.
                  </p>

                  <MembershipPackageTable
                    onCloseModal={this.onPackageModalClose}
                  />
                </div>
              </div>
            )}
            {this.props.match.params.list === "groupRegConfigSetup" && (
              <GroupRegFormConfiguration />
            )}
            {/* {this.props.match.params.list === "promote" && <Promote />} */}
            {this.props.match.params.list === "promote" && (
              <div style={{ width: "100#" }}>
                <p>
                  Create a post for social media. Download to save and share.
                  Use Onboard/Option #4 to generate a custom QR code providing
                  direct group access.
                </p>
                <Promotev2 />
              </div>
            )}
            {this.props.match.params.list === "fanSettings" && (
              <FanPermission goToPath={this.goToPath} />
            )}
            {/* {this.props.match.params.list === "merchandise" && <MerchandiseList />} */}
            <br></br>
          </div>
        </div>
      </>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.pagination &&
      this.props.adminData.members.records !== this.state.pagination.total &&
      !this.state.search
    ) {
      this.setState((prevState) => {
        let pagination = prevState.pagination
          ? { ...prevState.pagination }
          : {};
        pagination.total = this.props.adminData.members.records;
        pagination.defaultPageSize = pageSize;
        pagination.pageSize = pageSize;
        pagination.position = "bottom";
        return { pagination };
      });
      this.fetchFilteredMembers();
    }

    if (this.props.currentGroup !== prevProps.currentGroup) {
      this.setState({
        isLoading: true,
        pagination: {},
        tableData: [],
        search: "",
      });
      this.getPendingMembers();
      this.getPackages();
      this.props.dispatch(startAddMembers(this.props.currentGroup));
    }

    // handle tab change after mount
    if (this.props.match.params.list !== prevProps.match.params.list) {
      this.csvFile = null;
      this.setState({
        tableData: [],
        pagination: {},
        search: "",
        csvFileText: "",
      });
      this.fetchFilteredMembers();
    }

    if (
      this.state.newMembersDays !== prevState.newMembersDays &&
      this.state.newMembersDays
    ) {
      this.fetchFilteredMembers();
    }

    if (
      this.props.adminData.members.date !== prevProps.adminData.members.date
    ) {
      // this.setState({ refreshing: false })
    }
    if (this.props.match.params.list === "pending") {
      this.changeHeaderColor();
    }
  }

  getPendingMembers = async () => {
    await fbGetPendingMembers(this.props.currentGroup)
      .then((doc) => {
        if (doc.exists()) {
          let emails = doc.data().emails;
          let userDetails = doc.data().userDetails || {};
          let emailsWithPackage = [];
          let pendingEmails = Object.values(userDetails).map((detail) => {
            let packageId = detail.package;
            let packageName = "";
            let packageDetails = this.state.packages.find(
              (pack) => pack.id === packageId
            );
            if (packageDetails) {
              packageName = packageDetails.name;
            }
            emailsWithPackage.push(detail.email);

            return { email: detail.email, package: packageName };
          });

          emails.map((email) => {
            if (!emailsWithPackage.includes(email)) {
              pendingEmails.push({
                email,
                package: "",
              });
            }
          });

          this.setState({
            pendingEmails,
          });
        }
      })
      .catch((e) => logger.error(e));
  };

  getPackages = async () => {
    await getAllPackages(this.props.currentGroup).then((packageData) => {
      this.setState({ packages: packageData });
    });

    await fbGetFormLayout(this.props.currentGroup).then((data) => {
      if (data) {
        this.setState({ showAddMemberButton: true, formLayout: data });
      } else {
        this.setState({ showAddMemberButton: false });
      }
    });
  };

  changeHeaderColor = () => {
    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }

    let elements = document.querySelectorAll("th");
    elements.forEach((elem) => {
      elem.style.backgroundColor = primaryColor;
    });
  };

  componentDidMount() {
    this.getPackages().then(() => {
      this.getPendingMembers();
    });

    this.handleMemberRefresh();
    // TODO: Change implementation
    if (!this.props.match.params.list) {
      this.props.history.replace("/admin/members/all");
    }

    if (this.props.match.params.id) {
      getDoc(
        doc(
          db,
          "transaction_record",
          this.props.match.params.group,
          "records",
          this.props.match.params.id
        )
      )
        .then((doc) => {
          if (doc.exists()) {
            let data = doc.data();
            if (
              data.referrerId === getMyId() &&
              this.props.allGroups.includes(this.state.groupToAccept)
            ) {
              this.setState({
                registrationData: data,
              });
            } else {
              this.setState({
                idToAccept: "",
                showAddMemberModal: false,
              });
            }
          } else {
            this.setState({
              idToAccept: "",
              showAddMemberModal: false,
            });
          }
        })
        .catch((err) => {
          logger.error(
            `error while fetching data for ${this.props.match.params.id}: ${err.message}`
          );
        });
    }
    if (this.props.match.params.list === "pending") {
      this.changeHeaderColor();
    }

    // document.addEventListener('paste', this.handlePaste);
  }
}

class ResponsiveMobileTab extends React.Component {
  state = {
    viewDropdown: false,
    value: "",
    key: "",
  };

  static getDerivedStateFromProps(props) {
    let menuItem =
      props.menuItems.find((item) => item.key === props.param) || {};
    return {
      key: menuItem.key,
      value: menuItem.label,
    };
  }

  componentDidMount() {
    document.addEventListener("click", (e) => {
      if (this.dropdownRef) {
        if (!this.dropdownRef.contains(e.target) && this.state.viewDropdown) {
          this.setState({
            viewDropdown: false,
          });
        } else if (
          this.dropdownRef.contains(e.target) &&
          !this.state.viewDropdown
        ) {
          this.setState({
            viewDropdown: true,
          });
        }
      }
    });
  }
  render() {
    return window.innerWidth > 600 ? (
      this.props.menuItems.map((menuItem, index) => {
        let style =
          this.props.param === menuItem.key
            ? {
                borderBottom: `4px solid ${this.props.primaryColor}`,
              }
            : {};
        if (index > 0) {
          style.marginLeft = 24;
        }
        return (
          <li className="nav-item" onClick={this.props.changeTab} style={style}>
            <a
              name={menuItem.key}
              style={{
                height: "100%",
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              {menuItem.label}
            </a>
          </li>
        );
      })
    ) : (
      <div
        className="nav-item"
        ref={(ref) => (this.dropdownRef = ref)}
        style={{
          borderBottom: `4px solid ${this.props.primaryColor}`,
          position: "relative",
          display: "flex",
          alignItems: "center",
        }}
      >
        <a
          style={{
            height: "100%",
            fontWeight: "bold",
            fontSize: "22px",
            marginRight: 30,
          }}
        >
          {this.state.value}
        </a>
        <i
          class={`fa fa-caret-${this.state.viewDropdown ? "up" : "down"}`}
          aria-hidden="true"
        ></i>
        <select
          style={{
            border: "0px none transparent",
            background: "transparent",
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            zIndex: 1,
            opacity: 0,
            cursor: "pointer",
          }}
          value={this.state.key}
          tabIndex={this.props.tabIndex || 0}
          onChange={(e) => {
            let value = (
              this.props.menuItems.find(
                (item) => item.key === e.target.value
              ) || {}
            ).label;
            this.props.changeTab({
              target: { name: e.target.value },
              persist: () => {},
            });
            this.setState({
              key: e.target.value,
              value,
              viewDropdown: false,
            });
          }}
        >
          {this.props.menuItems.map((item) => {
            return (
              <option key={item.key} value={item.key}>
                {item.label}
              </option>
            );
          })}
        </select>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentGroup: state.adminData.currentGroup,
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
    allGroups: Object.keys(state.adminData.allGroups),
  };
};

export default connect(mapStateToProps)(ListMember);

export { ResponsiveMobileTab };
