import { fbGetContents } from "../../services/firebaseService/endPoints/admin/content";
import {
  addContentsToLocal,
  clearContentFromLocal,
  deleteContentFromLocal,
  editContentInLocal,
} from "../../services/indexedDb/content";
import { clearTicketFromLocal } from "../../services/indexedDb/tickets";
import { fbGetMembers } from "../../services/firebaseService/endPoints/admin/members";
import {
  addMembersToLocal,
  clearMembersFromLocal,
} from "../../services/indexedDb/members";
import { db } from "../../services/firebaseService/connection";
import { collection, getDocs } from "@firebase/firestore";

export const setGettingStartedExpanded = (expanded) => {
  return function (dispatch) {
    dispatch({
      type: "SET_GETTING_STARTED_EXPANDED",
      payload: expanded,
    });
  };
};

export const setGettingStartedItemStatus = (
  groupId,
  guideId,
  topicId,
  value
) => {
  return function (dispatch) {
    dispatch({
      type: "SET_GETTING_STARTED_ITEM_STATUS",
      payload: { groupId, guideId, topicId, value },
    });
  };
};

export const startAddContents = (tab, groupId) => {
  return async function (dispatch) {
    let contents = localStorage.getItem(`${tab}Contents-${groupId}`);
    if (contents) contents = JSON.parse(contents);
    // refresh in 6 hours
    if (
      (contents && Date.now() / 1000 - contents.date > 6 * 3600) ||
      !contents
    ) {
      contents = "";
      await refreshContents(tab, groupId);
    }

    fbGetContents(groupId, tab).then((contents) => {
      contents = contents.map((contents, index) => {
        return { ...contents, pageId: index };
      });
      let data = {
        records: contents.length,
        status: "available",
        date: parseInt(Date.now() / 1000),
      };

      if (contents.length === 0) {
        localStorage.setItem(`${tab}Contents-${groupId}`, JSON.stringify(data));
        dispatch({
          type: "UPDATE_CONTENTS",
          payload: { ...data, tab, groupId },
        });
        return;
      }

      addContentsToLocal(contents, tab, groupId).then(() => {
        localStorage.setItem(`${tab}Contents-${groupId}`, JSON.stringify(data));
        dispatch({
          type: "UPDATE_CONTENTS",
          payload: { ...data, tab, groupId },
        });
      });
    });
  };
};

export const startAddTickets = (tickets, tab, groupId) => {
  return async function (dispatch) {
    let data = {
      records: tickets.length,
      status: "available",
      date: parseInt(Date.now() / 1000),
    };

    localStorage.setItem(`${tab}Tickets-${groupId}`, JSON.stringify(data));
    dispatch({
      type: "UPDATE_TICKETS",
      payload: { ...data, tab, groupId },
    });

    return;
  };
};

export const startRefreshContents = (tab, groupId) => {
  return function (dispatch) {
    refreshContents(tab, groupId);
    dispatch({
      type: "UPDATE_CONTENTS",
      payload: { records: undefined, status: "process", tab, groupId },
    });
    // .then(() => {
    //     startAddContents(tab, groupId)
    // })
  };
};

export const startRefreshTickets = (tab, groupId) => {
  return function (dispatch) {
    refreshTickets(tab, groupId);
    dispatch({
      type: "UPDATE_CONTENTS",
      payload: { records: undefined, status: "process", tab, groupId },
    });
  };
};

export const refreshContents = (tab, groupId) => {
  localStorage.setItem(`${tab}Contents-${groupId}`, "");
  return clearContentFromLocal(tab, groupId);
};

export const refreshTickets = (tab, groupId) => {
  localStorage.setItem(`${tab}Contents-${groupId}`, "");
  return clearTicketFromLocal(tab, groupId);
};

export const startRefreshMembers = (groupId) => {
  return function (dispatch) {
    refreshMembers(groupId);
    dispatch({
      type: "UPDATE_MEMBERS",
      payload: { data: { records: undefined, status: "process" }, groupId },
    });
  };
};

const refreshMembers = (groupId) => {
  localStorage.setItem(`members-${groupId}`, "");
  return clearMembersFromLocal(groupId);
};

export const startAddContent = (content, groupId) => {
  return function (dispatch) {
    let data = JSON.parse(localStorage.getItem(`currentContents-${groupId}`));
    content = { ...content, pageId: 0 };
    addContentsToLocal([content], "current", groupId).then(() => {
      data.records += 1;
      localStorage.setItem(`currentContents-${groupId}`, JSON.stringify(data));
      dispatch({
        type: "UPDATE_CONTENTS",
        payload: { ...data, tab: "current", groupId },
      });
    });
  };
};

export const startEditContent = (content, groupId) => {
  return function (dispatch) {
    editContentInLocal(content, "current", groupId);
  };
};

export const startDeleteContent = (tab, groupId, contentId) => {
  return function (dispatch) {
    let contents = JSON.parse(
      localStorage.getItem(`${tab}Contents-${groupId}`)
    );
    deleteContentFromLocal(tab, groupId, contentId).then(() => {
      contents.records = contents.records - 1;
      localStorage.setItem(
        `${tab}Contents-${groupId}`,
        JSON.stringify(contents)
      );
      dispatch({
        type: "UPDATE_CONTENTS",
        payload: { ...contents, tab, groupId },
      });
    });
  };
};

export const startDeleteTicket = (tab, groupId, contentId) => {
  return function (dispatch) {
    let contents = JSON.parse(
      localStorage.getItem(`${tab}Contents-${groupId}`)
    );
    deleteContentFromLocal(tab, groupId, contentId).then(() => {
      contents.records = contents.records - 1;
      localStorage.setItem(
        `${tab}Contents-${groupId}`,
        JSON.stringify(contents)
      );
      dispatch({
        type: "UPDATE_CONTENTS",
        payload: { ...contents, tab, groupId },
      });
    });
  };
};

export const startAddMembers = (groupId) => {
  return async function (dispatch) {
    let members = localStorage.getItem(`members-${groupId}`);
    if (members) members = JSON.parse(members);
    // refresh in 6 hours
    if ((members && Date.now() / 1000 - members.date > 0) || !members) {
      members = "";
      await refreshMembers(groupId);
    }
    if (members) {
      dispatch({ type: "UPDATE_MEMBERS", payload: { ...members, groupId } });
    } else {
      fbGetMembers(groupId).then(async (members) => {
        members = members.map((contents, index) => {
          return {
            ...contents,
            pageId: index,
            joinedChant: "Yes",
            name:
              contents.name ||
              contents.fullName ||
              `${contents.firstName} ${contents.lastName}`,
            lockStatus: contents.lockStatus || false,
            address: contents.address1 || contents.address,
            fullName:
              contents.fullName ||
              contents.name ||
              `${contents.firstName} ${contents.lastName}`,
            tshirtSize: contents.tshirtSize || contents["t-shirtSize"],
            registrationDate:
              contents.registeredOn ||
              contents.registrationDate ||
              contents.registeredOnChantDate,
          };
        });

        let unregisteredUsers = await getDocs(
          collection(db, "invite_emails", groupId, "userDetails")
        ).then((snap) => {
          let members = [];
          let emailKeyMap = {};
          let userDetails = {};

          for (let doc of snap.docs) {
            if (doc.exists()) {
              let data = doc.data() || {};
              if (!doc.userId) {
                userDetails[doc.id] = data;

                let email = (data.email ?? "").toLowerCase();
                if (!emailKeyMap[email]) {
                  emailKeyMap[email] = doc.id;
                } else {
                  if (
                    emailKeyMap[email] === email &&
                    doc.id.toLocaleLowerCase() !== email
                  ) {
                    emailKeyMap[email] = doc.id;
                  }
                }
              }
            }
          }
          members = Object.values(emailKeyMap).map((key, index) => {
            let userDetail = userDetails[key];
            return {
              ...userDetail,
              userRole: userDetail?.userRole,
              id: -1 * (index + 1),
              name:
                userDetail.name ||
                userDetail.fullName ||
                `${userDetail.firstName} ${userDetail.lastName}`,
              lockStatus: userDetail.lockStatus || false,
              registrationDate: userDetail.registeredOn,
              address: userDetail.address || userDetail.address1,
              registeredOnChantDate: "",
              fullName:
                userDetail.fullName ||
                userDetail.name ||
                `${userDetail.firstName} ${userDetail.lastName}`,
              tshirtSize: userDetail.tshirtSize || userDetail["t-shirtSize"],
            };
          });
          return members;
        });

        let membersEmail = members.map((member) => member.email);

        unregisteredUsers.forEach((user) => {
          if (!membersEmail.includes(user.email)) {
            members.push({
              ...user,
              pageId: members.length,
              joinedChant: "No",
            });
          }
        });

        // let membersMembershipNumbers = members.map(member => member.membershipNumber);

        // unregisteredUsers.forEach(user => {
        //   if (!membersMembershipNumbers.includes(user.membershipNumber)) {
        //     members.push({ ...user, pageId: members.length, joinedChant: 'No' });
        //   }
        // });

        members.forEach((member) => {
          if (member.children && member.children.length === 0) {
            delete member.children;
          }
        });

        let data = {
          records: members.length,
          status: "available",
          date: parseInt(Date.now() / 1000),
        };

        addMembersToLocal(members, groupId).then(() => {
          localStorage.setItem(`members-${groupId}`, JSON.stringify(data));
          dispatch({ type: "UPDATE_MEMBERS", payload: { ...data, groupId } });
        });
      });
    }
  };
};

// export const startAddNewMembers = groupId => {
//   return function (dispatch) {
//     let localData = JSON.parse(localStorage.getItem(`members-${groupId}`));
//     if (localData && localData.date) {
//       fbGetLatestMembers(groupId, localData.date).then(async members => {
//         let allMembers = members.map((contents, index) => {
//           return { ...contents, pageId: index, joinedChant: 'Yes', name: contents.fullName || contents.name || `${contents.firstName} ${contents.lastName}`, lockStatus: contents.lockStatus || false, address: contents.address || contents.address1, fullName: contents.fullName || contents.name || `${contents.firstName} ${contents.lastName}`, tshirtSize: contents.tshirtSize || contents['t-shirtSize'], registrationDate: contents.registeredOn || contents.registrationDate || contents.registeredOnChantDate };
//         });

//         let unregisteredUsers = await getDocs(db.collection('invite_emails').doc(groupId).get().then(doc => {
//           let members = [];
//           if (doc.exists) {
//             let data = doc.data();
//             let userDetails = data.userDetails;
//             if (userDetails && typeof userDetails === 'object') {
//               members = Object.values(userDetails).map((userDetail, index) => ({ ...userDetail, userRole: 'member', id: -1 * (index + 1), name: userDetail.fullName || userDetail.name || `${userDetail.firstName} ${userDetail.lastName}`, lockStatus: false, registrationDate: userDetail.registeredOn, address: userDetail.address || userDetail.address1, registeredOnChantDate: '', fullName: userDetail.fullName || userDetail.name || `${userDetail.firstName} ${userDetail.lastName}`, tshirtSize: userDetail.tshirtSize || userDetail['t-shirtSize'] }))
//                 .filter(data => {
//                   return data.registeredOn > localData.date
//                 });
//             }
//           }
//           return members;
//         });

//         let membersEmail = allMembers.map(member => member.email);

//         unregisteredUsers.forEach(user => {
//           if (!membersEmail.includes(user.email)) {
//             allMembers.push({ ...user, pageId: 0, joinedChant: 'No' });
//           }
//         });

//         let data = {
//           records: localData.records + allMembers.length,
//           status: "available",
//           date: parseInt(Date.now() / 1000)
//         };
//         if (allMembers.length > 0) {
//           try {
//             await addMembersToLocal(allMembers, groupId);
//           } catch (err) {
//             return;
//           }
//         }
//         localStorage.setItem(`members-${groupId}`, JSON.stringify(data));
//         dispatch({ type: "UPDATE_MEMBERS", payload: { ...data, groupId } });
//       });
//     }
//   };
// };

// export const startEditMember = (content, groupId) => {
//     return function (dispatch) {
//         editMemberInLocal(content, groupId)
//     }
// }

export const adminGroupChange = (group) => {
  return { type: "GROUP_CHANGE", payload: group };
};

export const triggerContentsUpdate = () => {
  return {
    type: "TRIGGER_CONTENTS_UPDATE",
  };
};

export const dashboardChange = (dashboard) => {
  return { type: "DASHBOARD_CHANGE", payload: dashboard };
};
