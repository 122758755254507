import ReactDOM from "react-dom";
import React from "react";
import ReactCrop from "react-image-crop";
import Avatar from "react-avatar-edit";
import "react-image-crop/dist/ReactCrop.css";
import { Modal } from "antd";
import { isMobile } from "../../utils/helper";

const defaultAspectRatios = [
  { label: "Custom", value: null },
  { label: "16:9", value: 16 / 9 },
  { label: "5:4", value: 5 / 4 },
  { label: "1:1", value: 1 },
  { label: "5:2", value: 5 / 2 },
];

class ImageCrop extends React.Component {
  state = {
    src: this.props.src,
    crop: this.props.membershipCard
      ? {
          unit: "%",
          width: 100,
          height: 100,
          aspect: null,
          show: true,
        }
      : {
          unit: "%",
          width: 100,
          height: 100,
          // radius: 80,
          aspect: this.props.aspectRatio,
          show: true,
        },
    aspectIndex: -1,
  };

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    if (this.props.isAvatar === "false") {
      this.imageRef = image;
    } else {
      var img = document.createElement("img");
      img.src = image;
      this.imageRef = img;
    }
  };

  // onCropComplete = (crop = this.state.crop) => {
  //   this.makeClientCrop(this.state.crop);
  // };

  onCropComplete = (_) => {
    if (
      this.state.crop.x === 0 &&
      this.state.crop.y === 0 &&
      this.state.crop.height === 0
    ) {
      var crop = {
        aspect: null,
        x: 0,
        y: 0,
        unit: "px",
        width: this.imageRef.width,
        height: this.imageRef.height,
      };
      this.state.crop = crop;
    }
    this.makeClientCrop(this.state.crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };
  downscaleImage(image, maxWidth, maxHeight, quality = 1.0) {
    const canvas = document.createElement("canvas");
    let width = image.width;
    let height = image.height;

    // Only downscale if the image exceeds the maximum dimensions
    if (width > maxWidth || height > maxHeight) {
      const ratio = Math.min(maxWidth / width, maxHeight / height);
      width = Math.floor(width * ratio);
      height = Math.floor(height * ratio);
    }

    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext("2d");
    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = "high"; // Set image smoothing to high quality
    ctx.drawImage(image, 0, 0, width, height);

    return new Promise((resolve) => {
      canvas.toBlob(
        (blob) => {
          resolve(URL.createObjectURL(blob));
        },
        "image/jpeg",
        quality
      );
    });
  }
  async makeClientCrop(crop) {
    let imgToCrop = this.imageRef;

    if (isMobile()) {
      const downscaledImage = await this.downscaleImage(
        this.imageRef,
        Math.min(this.imageRef.width, 1920),
        Math.min(this.imageRef.height, 1080),
        1.0
      );

      imgToCrop = new Image();
      imgToCrop.src = downscaledImage;

      await new Promise((resolve) => (imgToCrop.onload = resolve));
    }

    const croppedImage = await this.getCroppedImg(
      imgToCrop,
      crop,
      "newFile.jpeg"
    );

    this.props.handleCropComplete(croppedImage);
  }

  getCroppedImg(image, crop, fileName) {
    let canvas, scaleX, scaleY, ctx;
    if (this.props.isAvatar === "false") {
      canvas = document.createElement("canvas");
      scaleX = image.naturalWidth / image.width;
      scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width * scaleY * 2;
      canvas.height = crop.height * scaleY * 2;
      ctx = canvas.getContext("2d");
      // ctx.imageSmoothingEnabled = false;
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        canvas.width,
        canvas.height
      );
    } else {
      canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      ctx = canvas.getContext("2d");
      // ctx.imageSmoothingEnabled = false;
      ctx.imageSmoothingQuality = "high";
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
    }

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(
          new File([blob], fileName, {
            type: blob.type,
            lastModified: Date.now(),
          })
        );
      }, "image/jpeg");
    });
  }

  render() {
    const { crop, croppedImageUrl, src } = this.state;
    const { isAvatar } = this.props;
    return (
      <>
        <Modal
          visible={true}
          onOk={this.onCropComplete}
          onCancel={this.props.handleCancelImageCrop}
        >
          <h5>Image Crop</h5>
          {/* React Crop package */}
          {isAvatar === "false" ? (
            <ReactCrop
              key={this.state.aspectIndex}
              src={this.props.src}
              crop={crop}
              onImageLoaded={this.onImageLoaded}
              // onComplete={this.onCropComplete}
              onChange={this.onCropChange}
              imageStyle={{
                background: "#ffffff",
              }}
            />
          ) : (
            <Avatar
              width={400}
              height={350}
              onCrop={this.onImageLoaded}
              // onClose={this.onClose}
              src={this.state.src}
              label="Click here to select an image"
            />
          )}

          <div className="d-flex" style={{ justifyContent: "space-between" }}>
            {!this.props.aspectRatio &&
              !this.props.membershipCard &&
              defaultAspectRatios.map((aspect, index) => {
                return (
                  <button
                    type="button"
                    className={
                      index === this.state.aspectIndex
                        ? "btn btn-sm btn-info mt-3 mb-2"
                        : "btn btn-sm btn-outline-info mt-3 mb-2"
                    }
                    onClick={() => {
                      if (index !== this.state.aspectIndex) {
                        this.setState(
                          {
                            crop: {
                              unit: "%",
                              width: 100,
                              aspect: aspect.label,
                              show: true,
                            },
                            aspectIndex: -1,
                          },
                          () => {
                            this.setState({
                              crop: {
                                unit: "%",
                                width: 100,
                                aspect: aspect.value,
                                show: true,
                              },
                              aspectIndex: index,
                            });
                          }
                        );
                      }
                    }}
                  >
                    {aspect.label}
                  </button>
                );
              })}
          </div>
        </Modal>
      </>
    );
  }
}

export default ImageCrop;
