import { Button, Checkbox, Modal, Tooltip } from "antd";
import React from "react";
import {
  getPackages,
  getRegsitrationLayout,
} from "../../../../services/firebaseService/endPoints/admin/members";
import { fbGetGroup } from "../../../../services/firebaseService/endPoints/group";
import { FInput } from "../../../commons/formFields/FInput";
import { FSelect } from "../../../commons/formFields/FSelect";
import { countries } from "countries-list";
import moment from "moment";

let countryNames = Object.values(countries).map((country) => {
  return { name: country.name };
});

let years = Array.from({ length: moment().year() - 1979 }, (_, index) => {
  return moment().year() - index;
}).map((year) => ({ year: `${year}` }));

let userRoles = ["admin", "member", "ticket-scanner", "capo", "leader", "fan"];

class EditMember extends React.Component {
  state = {
    groupName: "",
    packages: [],
    sizes: [],
    registrationLayout: {},
    customFields: {},
    hasTshirt: false,
    hasChildren: false,
    editedRecord: {},
    isFamilyOrCouplePackage: false,
  };

  handleInputChange = (e) => {
    this.setState({
      editedRecord: {
        ...this.state.editedRecord,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleSelectChange = (e) => {
    e.persist();
    e.preventDefault();
    this.setState({
      editedRecord: {
        ...this.state.editedRecord,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleChildInputChange = (e, index) => {
    let children = this.state.editedRecord.hasOwnProperty("children")
      ? this.state.editedRecord.children
      : this.props.editableRecord.children;

    if (!children) {
      children = [];
    }

    children[index][e.target.name] = e.target.value;

    this.setState({
      editedRecord: {
        ...this.state.editedRecord,
        children,
      },
    });
  };

  getProperty = (property, index) => {
    let propertyVal = "";
    if (
      this.state.editedRecord.children &&
      this.state.editedRecord.children[index]
    ) {
      propertyVal = this.state.editedRecord.children[index][property];
    }
    if (
      this.props.editableRecord.children &&
      this.props.editableRecord.children[index]
    ) {
      propertyVal = this.props.editableRecord.children[index][property];
    }
    return propertyVal;
  };

  render() {
    const { editableRecord, editableRecordId } = this.props;

    let selectedPackage = this.state.packages.find(
      (pack) => pack.id === editableRecord.package
    );
    let packageName = selectedPackage ? selectedPackage.name : "";

    let renewalDate = 0;
    if (
      editableRecord.subscriptionDetail &&
      editableRecord.subscriptionDetail.lastDate
    ) {
      let lastDate = moment.unix(editableRecord.subscriptionDetail.lastDate);
      renewalDate = lastDate.set("year", lastDate.get("year") + 1);
    }

    let dashboardColor = "#ffffff";
    let leftPanelColor = this.props.primaryColor;

    if (`${this.props.primaryColor}`.split("255").length === 4) {
      // dashboardColor = '#000000';
      leftPanelColor = "#000000";
    }

    return (
      <Modal
        width={1100}
        centered
        visible={this.props.editableRecordId}
        onOk={() => {
          this.props.saveDetail(
            this.state.editedRecord,
            editableRecordId,
            editableRecord.email
          );
          this.props.handleModalOpen();
        }}
        onCancel={() => {
          this.props.handleModalOpen();
        }}
        closable={false}
        footer={[
          <Button
            key="back"
            onClick={() => {
              this.props.handleModalOpen();
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              this.props.saveDetail(
                this.state.editedRecord,
                editableRecordId,
                editableRecord.email
              );
              // this.props.handleModalOpen();
            }}
          >
            Save
          </Button>,
        ]}
      >
        <div
          className="d-flex"
          style={{
            margin: "-24px -24px",
            position: "relative",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              cursor: "pointer",
              position: "absolute",
              top: 10,
              right: 20,
              zIndex: 10000,
            }}
            onClick={() => {
              this.props.handleModalOpen();
            }}
          >
            <span style={{ color: "#ffffff", fontSize: 20 }}> &#x2715; </span>
          </div>
          <div
            className="col-md-4"
            style={{
              background: leftPanelColor || "#145DA0",
              width: "20% !important",
              minWidth: 350,
            }}
          >
            {editableRecord.profileImage && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 80,
                  marginBottom: 30,
                }}
              >
                <div
                  style={{
                    borderRadius: "50%",
                    width: "80%",
                    paddingBottom: "80%",
                    backgroundImage: `url(${editableRecord.profileImage})`,
                    backgroundRepeat: "no-repeat",
                    overflow: "clip",
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
            )}
            <div
              style={{ color: dashboardColor, marginBottom: 40, marginTop: 30 }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div>
                  <div style={{ fontSize: "26px", fontWeight: "bold" }}>
                    {editableRecord.fullName}
                  </div>
                  <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                    {editableRecord.title}
                  </div>
                  <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                    {editableRecord.userRole}
                  </div>

                  <div
                    className="mt-4"
                    style={{ height: "70%", color: dashboardColor }}
                  >
                    <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                      {packageName}
                    </div>
                    <div style={{ fontSize: "18px" }}>
                      Member since {editableRecord.memberSince}
                    </div>
                    <div style={{ fontSize: "18px" }}>
                      Member # {editableRecord.membershipNumber}
                    </div>
                    <div style={{ fontSize: "18px" }}>
                      Volunteer:{" "}
                      {editableRecord.shareVolunteerOpportunities
                        ? "Yes"
                        : "No"}
                    </div>
                    <div style={{ fontSize: "18px" }}>
                      Send Email:{" "}
                      {editableRecord.joinedEmailList ? "Yes" : "No"}
                    </div>

                    <div className="row mt-4">
                      <FSelect
                        label={
                          <span
                            style={{
                              fontWeight: "bold",
                              color: dashboardColor,
                            }}
                          >
                            Role
                          </span>
                        }
                        value={
                          this.state.editedRecord.hasOwnProperty("userRole")
                            ? this.state.editedRecord.userRole
                            : editableRecord.userRole
                        }
                        onChange={this.handleSelectChange}
                        dataList={userRoles}
                        dataOnlyValue={true}
                        containerClassname="form-group-constrained"
                        name="userRole"
                        display="col"
                        placeholder="Select role"
                        tabIndex="2"
                        tooltip={
                          <img
                            src={require("../../../../images/member-role-tooltip.png")}
                            // style={{ width: 25, height: 25 }}
                          />
                        }
                      />
                    </div>
                    <div className="row">
                      <FInput
                        label={
                          <span
                            style={{
                              fontWeight: "bold",
                              color: dashboardColor,
                            }}
                          >
                            Title
                          </span>
                        }
                        value={
                          this.state.editedRecord.hasOwnProperty("title")
                            ? this.state.editedRecord.title
                            : editableRecord.title
                        }
                        onChange={this.handleInputChange}
                        containerClassname="form-group-constrained"
                        name="title"
                        display="col"
                        placeholder="Title"
                        bottomHint={
                          <div
                            className="d-flex"
                            style={{
                              gap: "12px",
                              marginTop: "1rem",
                              marginLeft: "0px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginLeft: "-16px",
                              }}
                            >
                              <Checkbox
                                style={{ marginLeft: "18px" }}
                                checked={
                                  this.state.editedRecord.hasOwnProperty(
                                    "showLeadersProfile"
                                  )
                                    ? this.state.editedRecord.showLeadersProfile
                                    : editableRecord.showLeadersProfile
                                }
                                onChange={(e) => {
                                  this.setState({
                                    editedRecord: {
                                      ...this.state.editedRecord,
                                      showLeadersProfile: e.target.checked,
                                    },
                                  });
                                }}
                                value="secondary"
                                color="primary"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                                tabIndex="5"
                              />
                            </div>
                            <p
                              className="d-flex justify-content-center align-items-center"
                              style={{ marginBottom: "0px" }}
                            >
                              Show profile in Leaders section of the Members
                              List (in the app). Admin are shown by default
                              providing members a contact.
                            </p>
                          </div>
                        }
                        tabIndex="2"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="px-5 py-5 col-md-8"
            style={{ background: "#4f4f4f", width: "80% !important" }}
          >
            <div>
              <div className="row">
                <div className="col">
                  <div
                    className="row"
                    style={{
                      justifyContent: "flex-end",
                      marginRight: "1rem",
                    }}
                  >
                    <span style={{ fontWeight: "bold", color: "#ffffff" }}>
                      Lock/Archive Account
                    </span>
                    <Checkbox
                      style={{ marginLeft: "18px" }}
                      checked={
                        this.state.editedRecord.hasOwnProperty("lockStatus")
                          ? this.state.editedRecord.lockStatus
                          : editableRecord.lockStatus
                      }
                      onChange={(e) => {
                        this.setState({
                          editedRecord: {
                            ...this.state.editedRecord,
                            lockStatus: e.target.checked,
                          },
                        });
                      }}
                      value="secondary"
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      tabIndex="2"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <FInput
                    label={
                      <span style={{ fontWeight: "bold", color: "#ffffff" }}>
                        Full Name
                      </span>
                    }
                    value={
                      this.state.editedRecord.hasOwnProperty("fullName")
                        ? this.state.editedRecord.fullName
                        : editableRecord.fullName
                    }
                    onChange={this.handleInputChange}
                    containerClassname="form-group-constrained"
                    name="fullName"
                    display="col"
                    placeholder="Enter first name"
                    tabIndex="2"
                  />
                </div>
                <div className="col-md-6">
                  <FInput
                    label={
                      <span style={{ fontWeight: "bold", color: "#ffffff" }}>
                        Email
                      </span>
                    }
                    value={
                      this.state.editedRecord.hasOwnProperty("email")
                        ? this.state.editedRecord.email
                        : editableRecord.email
                    }
                    onChange={this.handleInputChange}
                    containerClassname="form-group-constrained"
                    name="email"
                    display="col"
                    placeholder="Enter email"
                    tabIndex="2"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <FInput
                    label={
                      <span style={{ fontWeight: "bold", color: "#ffffff" }}>
                        Hometown
                      </span>
                    }
                    value={
                      this.state.editedRecord.hasOwnProperty("hometown")
                        ? this.state.editedRecord.hometown
                        : editableRecord.hometown
                    }
                    onChange={this.handleInputChange}
                    name="hometown"
                    containerClassname="form-group-constrained"
                    display="col"
                    placeholder="Enter hometown"
                    tabIndex="2"
                  />
                </div>
                <div className="col-md-6">
                  <FSelect
                    label={
                      <span style={{ fontWeight: "bold", color: "#ffffff" }}>
                        Member Since
                      </span>
                    }
                    value={
                      this.state.editedRecord.hasOwnProperty("memberSince")
                        ? this.state.editedRecord.memberSince
                        : editableRecord.memberSince
                    }
                    onChange={this.handleSelectChange}
                    dataList={years}
                    dataKey="year"
                    dataValue="year"
                    containerClassname="form-group-constrained"
                    name="memberSince"
                    display="col"
                    placeholder="Select year"
                    // defaultValue={`${moment().year()}`}
                    tabIndex="2"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <FInput
                    label={
                      <span style={{ fontWeight: "bold", color: "#ffffff" }}>
                        Phone
                      </span>
                    }
                    value={
                      this.state.editedRecord.hasOwnProperty("phone")
                        ? this.state.editedRecord.phone
                        : editableRecord.phone
                    }
                    onChange={this.handleInputChange}
                    name="phone"
                    display="col"
                    containerClassname="form-group-constrained"
                    placeholder="Enter phone"
                    tabIndex="2"
                  />
                </div>
                <div className="col-md-6">
                  <BirthDay
                    value={
                      this.state.editedRecord.hasOwnProperty("birthday")
                        ? this.state.editedRecord.birthday
                        : editableRecord.birthday || {}
                    }
                    onChange={(value) => {
                      this.handleInputChange({
                        target: { name: "birthday", value: value },
                      });
                    }}
                  />
                </div>
              </div>
              <div className="row">
                {this.state.packages.length > 0 && (
                  <div className="col-md-6">
                    <FSelect
                      label={
                        <span style={{ fontWeight: "bold", color: "#ffffff" }}>
                          Package
                        </span>
                      }
                      value={
                        this.state.editedRecord.hasOwnProperty("package")
                          ? this.state.editedRecord.package
                          : editableRecord.package
                      }
                      onChange={this.handleSelectChange}
                      dataList={this.state.packages}
                      dataKey="id"
                      dataValue="name"
                      containerClassname="form-group-constrained"
                      name="package"
                      display="col"
                      placeholder="Select package"
                      tabIndex="2"
                    />
                  </div>
                )}
                <div className="col-md-6">
                  <FInput
                    label={
                      <span style={{ fontWeight: "bold", color: "#ffffff" }}>
                        Family Membership
                      </span>
                    }
                    value={
                      this.props.linkedMember &&
                      editableRecord.familyMembershipId
                        ? this.props.linkedMember.name
                        : ""
                    }
                    onChange={
                      this.state.isFamilyOrCouplePackage &&
                      this.handleInputChange
                    }
                    name="familyMembershipId"
                    containerClassname="form-group-constrained"
                    disabled={true}
                    display="col"
                    placeholder="Family Membership"
                    tabIndex="2"
                  />
                </div>
              </div>
              {selectedPackage &&
                selectedPackage.offerAutorenewal &&
                editableRecord.subscriptionDetail && (
                  <div className="row">
                    <div className="col-md-6">
                      <FInput
                        label={
                          <span
                            style={{ fontWeight: "bold", color: "#ffffff" }}
                          >
                            Renewal Date
                          </span>
                        }
                        value={
                          editableRecord.subscriptionDetail
                            ? renewalDate.format("MM-DD-YYYY")
                            : ""
                        }
                        // onChange={this.state.isFamilyOrCouplePackage && this.handleInputChange}
                        name=""
                        containerClassname="form-group-constrained"
                        disabled={true}
                        display="col"
                        placeholder="Renewal Date"
                        tabIndex="2"
                      />
                    </div>
                    <div className="col-md-6">
                      <FInput
                        label={
                          <span
                            style={{ fontWeight: "bold", color: "#ffffff" }}
                          >
                            Auto-renewal status
                          </span>
                        }
                        value={
                          editableRecord.subscriptionDetail &&
                          editableRecord.subscriptionDetail.status === "active"
                            ? "Enabled"
                            : ""
                        }
                        onChange={
                          this.state.isFamilyOrCouplePackage &&
                          this.handleInputChange
                        }
                        name=""
                        containerClassname="form-group-constrained"
                        disabled={true}
                        display="col"
                        placeholder=""
                        tabIndex="2"
                        postTip={
                          <div className="d-flex">
                            <a
                              href="https://billing.stripe.com/p/login/28o02Y0m3cPafducMM"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {" "}
                              Cancel{" "}
                            </a>

                            <Tooltip
                              placement="topLeft"
                              title={() => (
                                <div
                                  style={{
                                    height: "fit-content",
                                    width: "400px",
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  Sends member an email to access the Stripe
                                  customer portal where they can cancel their
                                  subscription or change payment method. This
                                  same access is available in 'My Profile' of
                                  the app and in Stripe under Subscriptions.
                                </div>
                              )}
                            >
                              <span className="border-0">
                                &nbsp;
                                <i className="fa fa-question-circle-o"></i>
                              </span>
                            </Tooltip>
                          </div>
                        }
                      />
                    </div>
                  </div>
                )}
              <div className="row">
                <div className="col-md-6">
                  <FInput
                    label={
                      <span style={{ fontWeight: "bold", color: "#ffffff" }}>
                        Street name
                      </span>
                    }
                    value={
                      this.state.editedRecord.hasOwnProperty("address1")
                        ? this.state.editedRecord.address1
                        : editableRecord.address1
                    }
                    onChange={this.handleInputChange}
                    name="address1"
                    containerClassname="form-group-constrained"
                    display="col"
                    placeholder="Enter street address"
                    tabIndex="2"
                  />
                </div>
                <div className="col-md-6">
                  <FInput
                    label={
                      <span style={{ fontWeight: "bold", color: "#ffffff" }}>
                        Address2
                      </span>
                    }
                    value={
                      this.state.editedRecord.hasOwnProperty("address2")
                        ? this.state.editedRecord.address2
                        : editableRecord.address2
                    }
                    onChange={this.handleInputChange}
                    name="address2"
                    containerClassname="form-group-constrained"
                    display="col"
                    tabIndex="2"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <FInput
                    label={
                      <span style={{ fontWeight: "bold", color: "#ffffff" }}>
                        City
                      </span>
                    }
                    value={
                      this.state.editedRecord.hasOwnProperty("city")
                        ? this.state.editedRecord.city
                        : editableRecord.city
                    }
                    onChange={this.handleInputChange}
                    name="city"
                    display="col"
                    containerClassname="form-group-constrained"
                    placeholder="Enter city"
                    tabIndex="2"
                  />
                </div>
                <div className="col-md-6">
                  <FInput
                    label={
                      <span style={{ fontWeight: "bold", color: "#ffffff" }}>
                        State/Province/Region
                      </span>
                    }
                    value={
                      this.state.editedRecord.hasOwnProperty("state")
                        ? this.state.editedRecord.state
                        : editableRecord.state
                    }
                    onChange={this.handleInputChange}
                    name="state"
                    containerClassname="form-group-constrained"
                    display="col"
                    placeholder="Enter state"
                    tabIndex="2"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <FSelect
                    label={
                      <span style={{ fontWeight: "bold", color: "#ffffff" }}>
                        Country
                      </span>
                    }
                    value={
                      this.state.editedRecord.hasOwnProperty("country")
                        ? this.state.editedRecord.country
                        : editableRecord.country
                    }
                    onChange={this.handleSelectChange}
                    dataList={countryNames}
                    dataKey="name"
                    containerClassname="form-group-constrained"
                    dataValue="name"
                    name="country"
                    display="col"
                    placeholder="Select country"
                    defaultValue="United States"
                    tabIndex="2"
                  />
                </div>
                <div className="col-md-6">
                  <FInput
                    label={
                      <span style={{ fontWeight: "bold", color: "#ffffff" }}>
                        Zip/Postal
                      </span>
                    }
                    value={
                      this.state.editedRecord.hasOwnProperty("zip")
                        ? this.state.editedRecord.zip
                        : editableRecord.zip
                    }
                    onChange={this.handleInputChange}
                    name="zip"
                    display="col"
                    containerClassname="form-group-constrained"
                    placeholder="Enter zip"
                    tabIndex="2"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <FInput
                    label={
                      <span style={{ fontWeight: "bold", color: "#ffffff" }}>
                        Official Club Member
                      </span>
                    }
                    value={
                      this.state.editedRecord.hasOwnProperty(
                        "officialClubMember"
                      )
                        ? this.state.editedRecord.officialClubMember
                        : editableRecord.officialClubMember
                    }
                    onChange={this.handleInputChange}
                    name="officialClubMember"
                    display="col"
                    containerClassname="form-group-constrained"
                    placeholder="Official club member#"
                    tabIndex="2"
                  />
                </div>
                <div className="col-md-6">
                  <SeasonTicketHolder
                    value={
                      this.state.editedRecord.hasOwnProperty(
                        "seasonTicketHolder"
                      )
                        ? this.state.editedRecord.seasonTicketHolder
                        : editableRecord.seasonTicketHolder || {}
                    }
                    onChange={(value) => {
                      this.handleInputChange({
                        target: {
                          name: "seasonTicketHolder",
                          value: value,
                        },
                      });
                    }}
                  />
                </div>
                {this.state.hasTshirt && (
                  <div className="col-md-6">
                    <FSelect
                      label={
                        <span style={{ fontWeight: "bold", color: "#ffffff" }}>
                          T-shirt Size
                        </span>
                      }
                      value={
                        this.state.editedRecord.hasOwnProperty("tshirtSize")
                          ? this.state.editedRecord.tshirtSize
                          : editableRecord.tshirtSize
                      }
                      onChange={this.handleSelectChange}
                      dataList={this.state.sizes}
                      dataKey="name"
                      containerClassname="form-group-constrained"
                      dataValue="value"
                      name="tshirtSize"
                      display="col"
                      placeholder="Select T-shirt Size"
                      tabIndex="2"
                    />
                  </div>
                )}

                <div className="col-md-6">
                  <FInput
                    label={
                      <span style={{ fontWeight: "bold", color: "#ffffff" }}>
                        Referrer
                      </span>
                    }
                    value={
                      this.state.editedRecord.hasOwnProperty("referrer")
                        ? this.state.editedRecord.referrer
                        : editableRecord.referrer
                    }
                    onChange={this.handleInputChange}
                    name="referrer"
                    containerClassname="form-group-constrained"
                    display="col"
                    placeholder="Referrer"
                    tabIndex="2"
                  />
                </div>
                {this.state.registrationLayout.chapters &&
                  this.state.registrationLayout.chapters.length > 0 && (
                    <div className="col-md-6">
                      <FSelect
                        label={
                          <span
                            style={{ fontWeight: "bold", color: "#ffffff" }}
                          >
                            Chapters
                          </span>
                        }
                        value={
                          this.state.editedRecord.hasOwnProperty("chapter")
                            ? this.state.editedRecord.chapter
                            : editableRecord.chapter
                        }
                        onChange={this.handleSelectChange}
                        dataList={this.state.registrationLayout.chapters}
                        dataOnlyValue={true}
                        name="chapter"
                        containerClassname="form-group-constrained"
                        display="col"
                        placeholder="Select chapter"
                        tabIndex="2"
                      />
                    </div>
                  )}
              </div>
              <div className="row">
                {Object.keys(this.state.customFields).map((key) => {
                  let value = this.state.customFields[key];
                  return (
                    <div className="col-md-6">
                      {this.getCustomField(value, key, editableRecord)}
                    </div>
                  );
                })}
              </div>

              {this.state.hasChildren && (
                <>
                  <div className="row">
                    {/* <div className="col-md-6">
                                    <FInput
                                        label="No of Children"
                                        value={this.state.editedRecord.hasOwnProperty("noOfChildren") ? this.state.editedRecord.noOfChildren : editableRecord.noOfChildren}
                                        onChange={this.handleInputChange}
                                        name="noOfChildren"
                                        display="col"
                                        placeholder="# of children"
                                        tabIndex="2"
                                        maxLength={2}
                                    />
                                </div> */}
                  </div>
                  {Array.from({
                    length: this.state.editedRecord.hasOwnProperty(
                      "noOfChildren"
                    )
                      ? parseInt(this.state.editedRecord.noOfChildren)
                      : parseInt(editableRecord.noOfChildren),
                  }).map((_, index) => {
                    return (
                      <div className="row">
                        <div className="col-md-6">
                          <FInput
                            label={
                              <span
                                style={{
                                  fontWeight: "bold",
                                  color: "#ffffff",
                                }}
                              >{`Child #${index + 1} Name`}</span>
                            }
                            value={this.getProperty("name", index)}
                            onChange={(e) => {
                              e.persist();
                              e.preventDefault();
                              this.handleChildInputChange(e, index);
                            }}
                            name="name"
                            display="col"
                            containerClassname="form-group-constrained"
                            placeholder="Name"
                            tabIndex="2"
                          />
                        </div>

                        {this.state.hasTshirt && (
                          <div className="col-md-6">
                            <FSelect
                              label={
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "#ffffff",
                                  }}
                                >
                                  T-shirt Size
                                </span>
                              }
                              value={this.getProperty("tshirtSize", index)}
                              onChange={this.handleSelectChange}
                              dataList={this.state.sizes}
                              dataKey="name"
                              dataValue="value"
                              name="tshirtSize"
                              containerClassname="form-group-constrained"
                              display="col"
                              placeholder="Select T-shirt Size"
                              tabIndex="2"
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </>
              )}
              <div className="row">
                <div className="col">
                  <div className="col">
                    <div>
                      <label
                        style={{
                          fontWeight: "bold",
                          color: dashboardColor,
                          marginBottom: 0,
                        }}
                      >
                        Notes
                      </label>
                    </div>
                    <textarea
                      className="col"
                      style={{
                        border: 0,
                        outline: "none",
                        borderRadius: 5,
                      }}
                      value={
                        this.state.editedRecord.hasOwnProperty("notes")
                          ? this.state.editedRecord.notes
                          : editableRecord.notes
                      }
                      onChange={(e) => {
                        let editedRecord = this.state.editedRecord;
                        editedRecord.notes = e.target.value;
                        this.setState({
                          editedRecord,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  getCustomField(fieldDetails, fieldKey, editableRecord) {
    switch (fieldDetails.fieldType) {
      case "text":
        return (
          <FInput
            label={
              <span
                style={{ fontWeight: "bold", color: "#ffffff" }}
              >{`${fieldDetails.fieldTitle}`}</span>
            }
            value={
              this.state.editedRecord.hasOwnProperty(fieldKey)
                ? this.state.editedRecord[fieldKey]
                : editableRecord[fieldKey]
            }
            onChange={this.handleInputChange}
            containerClassname="form-group-constrained"
            name={fieldKey}
            display="col"
            placeholder={fieldDetails.fieldHelp}
            tabIndex="2"
          />
        );
      case "checkbox":
        return (
          <div className="col">
            <div className="d-flex form-group-constrained">
              <div style={{ flexGrow: 1 }}>
                <span
                  style={{ fontWeight: "bold", color: "#ffffff" }}
                >{`${fieldDetails.fieldTitle}`}</span>
              </div>
              <Checkbox
                style={{ marginLeft: "18px" }}
                checked={
                  this.state.editedRecord.hasOwnProperty(fieldKey)
                    ? this.state.editedRecord[fieldKey]
                    : editableRecord[fieldKey]
                }
                onChange={(e) => {
                  this.setState({
                    editedRecord: {
                      ...this.state.editedRecord,
                      [fieldKey]: e.target.checked,
                    },
                  });
                }}
                value="secondary"
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
                tabIndex="5"
              />
            </div>
          </div>
        );
      case "dropdown":
        return (
          <FSelect
            label={
              <span style={{ fontWeight: "bold", color: "#ffffff" }}>
                {fieldDetails.fieldTitle}
              </span>
            }
            value={
              this.state.editedRecord.hasOwnProperty(fieldKey)
                ? this.state.editedRecord[fieldKey]
                : editableRecord[fieldKey]
            }
            onChange={this.handleSelectChange}
            containerClassname="form-group-constrained"
            dataList={fieldDetails.dropdownValues || []}
            dataOnlyValue={true}
            name={fieldKey}
            display="col"
            placeholder={fieldDetails.fieldHelp}
            tabIndex="2"
          />
        );
      case "number":
        return (
          <FInput
            label={
              <span
                style={{ fontWeight: "bold", color: "#ffffff" }}
              >{`${fieldDetails.fieldTitle}`}</span>
            }
            value={
              this.state.editedRecord.hasOwnProperty(fieldKey)
                ? this.state.editedRecord[fieldKey]
                : editableRecord[fieldKey]
            }
            onChange={this.handleInputChange}
            containerClassname="form-group-constrained"
            name={fieldKey}
            type="number"
            display="col"
            placeholder={fieldDetails.fieldHelp}
            tabIndex="2"
          />
        );
      default:
        return <></>;
    }
  }

  componentDidMount() {
    const handleMount = async () => {
      if (this.props.groupId) {
        let groupName = await fbGetGroup(this.props.groupId).then((data) => {
          return data.groupName;
        });
        let packageDetails = await getPackages(this.props.groupId).then(
          (packages) => {
            let sizes = [];
            let hasTshirt = false;
            let hasChildren = false;
            let isFamilyOrCouplePackage = false;
            let currentPackage = this.props.editableRecord.package
              ? packages.find(
                  (pack) => pack.id === this.props.editableRecord.package
                )
              : null;
            if (
              packages.length > 0 &&
              this.props.editableRecord.package &&
              currentPackage &&
              (currentPackage.includesTshirt || currentPackage.askTshirtSize)
            ) {
              hasTshirt = true;
              sizes =
                packages.find(
                  (pack) => pack.id === this.props.editableRecord.package
                ).sizes ||
                packages.find(
                  (pack) => pack.id === this.props.editableRecord.package
                ).tshirtSizes;
              sizes = sizes.map((size) => ({ name: size, value: size }));
            }

            if (
              packages.length > 0 &&
              this.props.editableRecord.package &&
              currentPackage &&
              (currentPackage.hasNoOfChildren ||
                currentPackage.includesChildPrice ||
                currentPackage.price.type === "Per Family")
            ) {
              hasChildren = true;
            }

            let workingPackage = this.props.editableRecord.package
              ? packages.find(
                  (pack) => pack.id === this.props.editableRecord.package
                )
              : currentPackage;

            if (packages.length > 0 && workingPackage) {
              if (
                workingPackage.price.type === "Per family" ||
                workingPackage.price.type === "Per couple"
              ) {
                isFamilyOrCouplePackage = true;
              } else {
                isFamilyOrCouplePackage = false;
              }
            }

            let currentPackages = packages.filter((pack) =>
              moment.unix(pack?.packageExpiryDate).isAfter(moment())
            );

            return {
              packages: currentPackages,
              sizes,
              hasTshirt,
              hasChildren,
              editedRecord: {},
              isFamilyOrCouplePackage,
            };
          }
        );

        await getRegsitrationLayout(this.props.groupId).then((data) => {
          let registrationLayoutData = data || {};
          let customFields = {};
          if (
            registrationLayoutData &&
            registrationLayoutData.customFieldDetails
          ) {
            customFields = registrationLayoutData.customFieldDetails;
          }
          this.setState({
            registrationLayout: data || {},
            ...packageDetails,
            groupName,
            customFields: customFields,
          });
        });
      }
    };

    handleMount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.groupId !== this.props.groupId ||
      prevProps.editableRecordId !== this.props.editableRecordId
    ) {
      fbGetGroup(this.props.groupId).then((data) => {
        let groupName = data.groupName;
        this.setState({ groupName: groupName }, async () => {
          let packageData = await getPackages(this.props.groupId).then(
            (packages) => {
              let sizes = [];
              let hasTshirt = false;
              let hasChildren = false;
              let isFamilyOrCouplePackage = false;
              let currentPackage = this.props.editableRecord.package
                ? packages.find(
                    (pack) => pack.id === this.props.editableRecord.package
                  )
                : null;
              if (
                packages.length > 0 &&
                this.props.editableRecord.package &&
                currentPackage &&
                (currentPackage.includesTshirt || currentPackage.askTshirtSize)
              ) {
                hasTshirt = true;
                sizes =
                  packages.find(
                    (pack) => pack.id === this.props.editableRecord.package
                  ).sizes ||
                  packages.find(
                    (pack) => pack.id === this.props.editableRecord.package
                  ).tshirtSizes;
                sizes = sizes.map((size) => ({ name: size, value: size }));
              }

              if (
                packages.length > 0 &&
                this.props.editableRecord.package &&
                currentPackage &&
                (currentPackage.hasNoOfChildren ||
                  currentPackage.includesChildPrice ||
                  currentPackage.price.type === "Per Family")
              ) {
                hasChildren = true;
              }

              let workingPackage = this.props.editableRecord.package
                ? packages.find(
                    (pack) => pack.id === this.props.editableRecord.package
                  )
                : currentPackage;

              if (packages.length > 0 && workingPackage) {
                if (
                  workingPackage.price.type === "Per family" ||
                  workingPackage.price.type === "Per couple"
                ) {
                  isFamilyOrCouplePackage = true;
                } else {
                  isFamilyOrCouplePackage = false;
                }
              }
              return {
                packages,
                sizes,
                hasTshirt,
                hasChildren,
                editedRecord: {},
                isFamilyOrCouplePackage,
              };
            }
          );

          await getRegsitrationLayout(this.props.groupId).then((data) => {
            let registrationLayoutData = data || {};
            let customFields = {};
            if (
              registrationLayoutData &&
              registrationLayoutData.customFieldDetails
            ) {
              customFields = registrationLayoutData.customFieldDetails;
            }
            this.setState({
              registrationLayout: data || {},
              ...packageData,
              groupName,
              customFields: customFields,
            });
          });
        });
      });
    }
    if (prevState.editedRecord.package !== this.state.editedRecord.package) {
      let selectedPackage = this.state.packages.find(
        (pack) => pack.id === this.state.editedRecord.package
      );
      let hasChildren = selectedPackage
        ? selectedPackage.hasNoOfChildren || selectedPackage.includesChildPrice
        : false;
      let hasTshirt = selectedPackage
        ? selectedPackage.includesTshirt || selectedPackage.askTshirtSize
        : false;
      let isFamilyOrCouplePackage = selectedPackage
        ? selectedPackage.price.type === "Per family" ||
          selectedPackage.price.type === "Per couple"
        : false;
      let sizes =
        this.state.packages.length > 0
          ? selectedPackage
            ? selectedPackage.sizes || selectedPackage.tshirtSizes
            : []
          : [];

      sizes = sizes.map((size) => ({ name: size, value: size }));

      let updatedState = {
        sizes,
        hasChildren,
        hasTshirt,
        isFamilyOrCouplePackage,
      };
      let editedRecord = {
        ...this.state.editedRecord,
      };

      if (!hasTshirt) {
        editedRecord.tshirtSize = "";
      }

      if (!hasChildren) {
        editedRecord.children = [];
      }

      updatedState.editedRecord = editedRecord;

      this.setState(updatedState);
    }
  }
}

const Months = {
  Jan: 31,
  Feb: 29,
  Mar: 31,
  Apr: 30,
  May: 31,
  Jun: 30,
  Jul: 31,
  Aug: 31,
  Sep: 30,
  Oct: 31,
  Nov: 30,
  Dec: 31,
};

class BirthDay extends React.Component {
  state = {
    month: "",
    date: "",
    year: "",
  };

  render() {
    return (
      <div className="col form-group-constrained">
        <span style={{ fontWeight: "bold", color: "#ffffff" }}>Birthday</span>
        <div className="d-flex">
          <div style={{ flex: 1, paddingRight: 3 }}>
            <select
              value={this.state.month || this.props.value.month}
              onChange={(e) => {
                let updatedValue = {
                  ...(this.props.value || {}),
                  month: e.target.value,
                };
                this.setState({
                  month: e.target.value,
                });
                this.props.onChange(updatedValue);
              }}
              tabIndex={2}
              className={`form-control form-control-sm col"}`}
            >
              <option key="0" value={""}>
                Month
              </option>
              {Object.keys(Months).map((data) => {
                return (
                  <option key={data} value={data}>
                    {data}
                  </option>
                );
              })}
            </select>
          </div>
          <div style={{ flex: 1, padding: "0px 3px" }}>
            <select
              value={this.state.date || this.props.value.date}
              onChange={(e) => {
                let updatedValue = {
                  ...(this.props.value || {}),
                  date: e.target.value,
                };
                this.setState({
                  date: e.target.value,
                });
                this.props.onChange(updatedValue);
              }}
              tabIndex={2}
              className={`form-control form-control-sm col"}`}
            >
              <option key="0" value={""}>
                Date
              </option>
              {Array.from(
                { length: Months[this.state.month || this.props.value.month] },
                (_, index) => {
                  return (
                    <option key={index + 1} value={index + 1}>
                      {index + 1}
                    </option>
                  );
                }
              )}
            </select>
          </div>
          <div style={{ flex: 1, paddingLeft: "3px" }}>
            <select
              value={this.state.year || this.props.value.year}
              onChange={(e) => {
                let updatedValue = {
                  ...(this.props.value || {}),
                  year: e.target.value,
                };
                this.setState({
                  year: e.target.value,
                });
                this.props.onChange(updatedValue);
              }}
              tabIndex={2}
              className={`form-control form-control-sm col"}`}
            >
              <option key="0" value={""}>
                Year
              </option>
              {Array.from({ length: moment().year() - 1922 }, (_, index) => {
                return (
                  <option key={index + 1} value={1922 + index + 1}>
                    {1922 + index + 1}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
    );
  }
}

class SeasonTicketHolder extends React.Component {
  state = {
    season: "",
    seats: "",
  };

  render() {
    return (
      <div className="col form-group-constrained">
        <span style={{ fontWeight: "bold", color: "#ffffff" }}>
          Season Ticket Holder
        </span>
        <div className="row">
          <div className="col-md-6">
            <div className="col">
              <FInput
                value={
                  this.state.season ||
                  this.props.value.season ||
                  this.props.value.section
                }
                onChange={(e) => {
                  let updatedValue = {
                    ...(this.props.value || {}),
                    season: e.target.value,
                  };
                  this.setState({
                    season: e.target.value,
                  });
                  this.props.onChange(updatedValue);
                }}
                display="row"
                placeholder="Section"
                tabIndex="2"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="col">
              <FInput
                value={
                  this.state.seats ||
                  this.props.value.seats ||
                  this.props.value.noOfSeats
                }
                onChange={(e) => {
                  let updatedValue = {
                    ...(this.props.value || {}),
                    seats: e.target.value,
                  };
                  this.setState({
                    seats: e.target.value,
                  });
                  this.props.onChange(updatedValue);
                }}
                display="row"
                placeholder="No. of seats"
                tabIndex="2"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditMember;
