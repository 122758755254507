import { Button, Layout, Menu, Popconfirm } from "antd";
import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { CssManipulator } from "../../helperFunctions/util";
import { fbLogoutUser } from "../../services/firebaseService/endPoints/user";
import { adminGroupChange } from "../../redux/actions/adminData";
import "./SideNav.css";
import FeatureBadge from "../commons/FeatureBadge";

const { Sider } = Layout;
const { SubMenu } = Menu;

class SideNav extends React.Component {
  state = {
    expanded: this.props.expanded,
    width: window.innerWidth,
    openKeys: [],
    currentPath: window.location.pathname,
    manuallyToggled: false,
  };

  componentDidMount() {
    window.onresize = () => {
      this.setState({
        width: window.innerWidth,
      });
    };

    let path = window.location.pathname.split("/")[1];

    if (
      this.props.dashboard !== "superadmin" &&
      this.props.user.role === "super-admin" &&
      path === "superadmin"
    ) {
      this.props.dispatch(
        adminGroupChange({ group: "", dashboard: "superadmin" })
      );
    }
  }

  subMenuParentMap = {
    "/admin/members": "sub1",
    "/admin/membershipcard": "sub1",

    "/admin/content": "sub2",
    "/admin/welcome": "sub2",
    "/admin/gallery": "sub2",
    "/admin/partners": "sub2",
    "/admin/chants": "sub2",

    "/admin/store": "sub3",
    "/admin/store/orders": "sub3",

    "/admin/tickets": "sub4",

    "/admin/website": "sub5",

    "/admin/stadium": "sub6",

    "/admin/directory": "sub7",

    "/admin/help": null,

    "/admin/groupSettings": "sub8",
    "/admin/appSettings": "sub8",
    "/admin/paymentDetails": "sub8",
  };

  componentDidUpdate(prevProps, prevState) {
    const { currentPath } = this.state;
    const locationPath = window.location.pathname;

    if (locationPath && currentPath !== locationPath) {
      window.scrollTo(0, 0);
      this.setState({ currentPath: locationPath });
    }

    let path = window.location.pathname.split("/");
    const updatedPath = `/${path[1]}/${path[2]}`;

    if (
      prevState.currentPath !== window.location.pathname &&
      this.subMenuParentMap[updatedPath] &&
      this.state.openKeys[0] !== this.subMenuParentMap[updatedPath] &&
      !this.state.manuallyToggled
    ) {
      this.setState({
        openKeys: [this.subMenuParentMap[updatedPath]],
        currentPath: updatedPath,
        manuallyToggled: false,
      });
    }

    if (this.state.manuallyToggled) {
      this.setState({ manuallyToggled: false });
    }
  }

  render() {
    let backgroundColor = "#ff4040";
    let color = "#ffffff";
    let style = {};

    if (this.props.adminData) {
      let group =
        this.props.adminData.allGroups[this.props.adminData.currentGroup];
      let groupData = group ? group.data : null;

      if (groupData) {
        if (groupData.configuration && groupData.configuration.primaryColor) {
          let { r, g, b } = groupData.configuration.primaryColor;
          backgroundColor = `rgb(${r}, ${g}, ${b})`;
          if ([r, g, b].filter((e) => `${e}` === "255").length === 3) {
            // let { r, g, b } = groupData.configuration.secondaryColor || {r:0, b:0, g:0};
            color = `rgb(0, 0, 0)`;
            style = {
              boxShadow: "0px 1px 3px 0px",
            };
          }
        }
      }
    }

    const rootEl = document.querySelector(":root");
    rootEl.style.setProperty("--main-color", backgroundColor);
    rootEl.style.setProperty("--step-color", backgroundColor);
    rootEl.style.setProperty("--main-font-color", color);

    rootEl.style.setProperty(
      "--top-margin",
      this.props.expanded ? "0px" : "-5px"
    );

    let props = {};
    if (this.state.width < 500) {
      props.trigger = null;
    }
    let pageSheet = new CssManipulator();
    pageSheet.change(
      ".ant-menu-dark.ant-menu-submenu-popup",
      "background-color",
      backgroundColor
    );
    return (
      <Sider
        breakpoint="xl"
        {...props}
        collapsedWidth={this.state.width > 500 ? "80" : "0"}
        collapsed={!this.props.expanded}
        collapsible
        // onBreakpoint={broken => {
        // }}
        onCollapse={(collapsed, type) => {
          this.props.handleNavCollapse(collapsed);
          this.setState({
            expanded: !collapsed,
          });
        }}
        style={{
          backgroundColor: backgroundColor,
          position: "fixed",
          overflow: "auto",
          height: "100vh",
          ...style,
          "--sidenav-trigger-bg-color": backgroundColor,
        }}
        className="side-nav"
      >
        {this.props.user.role === "admin" &&
          this.props.dashboard === "admin" &&
          this.getAdminMenu(color)}
        {this.props.user.role === "admin" &&
          this.props.dashboard === "clubadmin" &&
          this.getClubadminMenu(color)}
        <div>
          <Popconfirm
            title="Are you sure?"
            onCancel={() => {}}
            onConfirm={() => {
              this.handleLogout();
            }}
            okText="Yes"
            cancelText="No"
          >
            <div
              className="sidenav-logout-btn"
              style={{
                "--logout-btn-bg-color": backgroundColor,
                "--logout-btn-text-color": "white",
              }}
            >
              {this.props.expanded ? (
                <Button
                  className="btn-info"
                  style={{
                    background: "transparent",
                    width: 100,
                    borderColor: color,
                    color: color,
                  }}
                  block
                >
                  Logout
                </Button>
              ) : (
                <i className="anticon cursor-pointer">
                  <img
                    alt="logout-button"
                    src={require("../../images/nav-icons/baseline_logout_white_24dp.png")}
                    width={22}
                  />
                </i>
              )}
            </div>
          </Popconfirm>
        </div>
      </Sider>
    );
  }

  handleLogout = () => {
    // window.location.href = "/"

    fbLogoutUser().then(() => {
      this.props.history.push("/login");
    });
    // this.props.history.push("/")
  };

  getAdminMenu(color) {
    let onOpenChange = (keys) => {
      this.setState({
        openKeys: keys,
        manuallyToggled: true,
      });
    };

    return (
      <Menu
        style={{
          backgroundColor: "transparent",
        }}
        mode="inline"
        defaultSelectedKeys={[window.location.pathname]}
        theme="dark"
        selectedKeys={[window.location.pathname]}
        openKeys={this.state.openKeys}
        onOpenChange={onOpenChange}
      >
        <SubMenu
          key="sub1"
          title={
            <span style={{ display: "flex", alignItems: "center" }}>
              <i className="anticon" color={color}>
                <img
                  alt="members-page"
                  src={require("../../images/nav-icons/baseline_people_white_48dp.png")}
                  width={22}
                />
              </i>
              <span className="nav-text" style={{ color: color }}>
                MEMBERS
              </span>
            </span>
          }
          style={{
            backgroundColor: "transparent",
          }}
        >
          <Menu.Item
            key="/admin/members/all"
            onClick={
              this.state.width > 500
                ? () => {}
                : () => {
                    this.props.handleNavCollapse(true);
                  }
            }
          >
            <NavLink
              to="/admin/members/all"
              className="nav-link default-text-color"
            >
              Members
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key="/admin/members/purchaseReport"
            onClick={
              this.state.width > 500
                ? () => {}
                : () => {
                    this.props.handleNavCollapse(true);
                  }
            }
          >
            <NavLink
              to="/admin/members/purchaseReport"
              className="nav-link default-text-color"
            >
              <div className="d-flex" style={{ gap: 5, alignItems: "center" }}>
                Purchases
                <FeatureBadge type="beta" />
              </div>
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key="/admin/members/groupRegConfigPackage"
            onClick={
              this.state.width > 500
                ? () => {}
                : () => {
                    this.props.handleNavCollapse(true);
                  }
            }
          >
            <NavLink
              to="/admin/members/groupRegConfigPackage"
              className="nav-link"
            >
              Packages
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key="/admin/members/csv"
            onClick={
              this.state.width > 500
                ? () => {}
                : () => {
                    this.props.handleNavCollapse(true);
                  }
            }
          >
            <NavLink to="/admin/members/csv" className="nav-link">
              Onboard
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key="/admin/members/promote"
            onClick={
              this.state.width > 500
                ? () => {}
                : () => {
                    this.props.handleNavCollapse(true);
                  }
            }
          >
            <NavLink to="/admin/members/promote" className="nav-link">
              <div className="d-flex" style={{ gap: 5, alignItems: "center" }}>
                Promote
                <FeatureBadge type="new" />
              </div>
            </NavLink>
          </Menu.Item>

          <Menu.Item
            key="/admin/membershipcard"
            onClick={
              this.state.width > 500
                ? () => {}
                : () => {
                    this.props.handleNavCollapse(true);
                  }
            }
          >
            <NavLink to="/admin/membershipcard" className="nav-link">
              Card
            </NavLink>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="sub2"
          title={
            <span style={{ display: "flex", alignItems: "center" }}>
              <i className="anticon">
                <img
                  alt="content-page"
                  src={require("../../images/nav-icons/baseline_post_add_white_48dp.png")}
                  width={22}
                />
              </i>
              <span style={{ color: color }}>CONTENT</span>
            </span>
          }
          style={{
            backgroundColor: "transparent",
          }}
        >
          <Menu.Item
            key="/admin/content"
            onClick={
              this.state.width > 500
                ? () => {}
                : () => {
                    this.props.handleNavCollapse(true);
                  }
            }
          >
            <NavLink to="/admin/content" className="nav-link">
              News/Events
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key="/admin/welcome"
            onClick={
              this.state.width > 500
                ? () => {}
                : () => {
                    this.props.handleNavCollapse(true);
                  }
            }
          >
            <NavLink to="/admin/welcome" className="nav-link">
              <div className="d-flex" style={{ gap: 5, alignItems: "center" }}>
                Welcome
              </div>
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key="/admin/gallery"
            onClick={
              this.state.width > 500
                ? () => {}
                : () => {
                    this.props.handleNavCollapse(true);
                  }
            }
          >
            <NavLink to="/admin/gallery" className="nav-link">
              Gallery
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key="/admin/partners/list"
            onClick={
              this.state.width > 500
                ? () => {}
                : () => {
                    this.props.handleNavCollapse(true);
                  }
            }
          >
            <NavLink to="/admin/partners/list" className="nav-link">
              <div className="d-flex" style={{ gap: 5, alignItems: "center" }}>
                Partners
              </div>
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key="/admin/chants"
            onClick={
              this.state.width > 500
                ? () => {}
                : () => {
                    this.props.handleNavCollapse(true);
                  }
            }
          >
            <NavLink to="/admin/chants" className="nav-link">
              Our Chants
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key="/admin/content/leaderboard"
            onClick={
              this.state.width > 500
                ? () => {}
                : () => {
                    this.props.handleNavCollapse(true);
                  }
            }
          >
            <NavLink to="/admin/content/leaderboard" className="nav-link">
              Leaderboard
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key="/admin/content/locations"
            onClick={
              this.state.width > 500
                ? () => {}
                : () => {
                    this.props.handleNavCollapse(true);
                  }
            }
          >
            <NavLink to="/admin/content/locations" className="nav-link">
              <div className="d-flex" style={{ gap: 5, alignItems: "center" }}>
                Locations
              </div>
            </NavLink>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="sub3"
          title={
            <span style={{ display: "flex", alignItems: "center" }}>
              <i className="anticon">
                <img
                  alt="store-page"
                  src={require("../../images/nav-icons/outline_shopping_cart_white_48dp.png")}
                  width={22}
                />
              </i>
              <span style={{ color: color }}>STORE</span>
            </span>
          }
          style={{
            backgroundColor: "transparent",
          }}
        >
          <Menu.Item
            key="/admin/store/orders"
            onClick={
              this.state.width > 500
                ? () => {}
                : () => {
                    this.props.handleNavCollapse(true);
                  }
            }
          >
            <NavLink to="/admin/store/orders" className="nav-link">
              Orders
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key="/admin/store/active-merch"
            onClick={
              this.state.width > 500
                ? () => {}
                : () => {
                    this.props.handleNavCollapse(true);
                  }
            }
          >
            <NavLink to="/admin/store/active-merch" className="nav-link">
              Merchandise
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key="/admin/store/settings"
            onClick={
              this.state.width > 500
                ? () => {}
                : () => {
                    this.props.handleNavCollapse(true);
                  }
            }
          >
            <NavLink to="/admin/store/settings" className="nav-link">
              Settings
            </NavLink>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="sub4"
          title={
            <span style={{ display: "flex", alignItems: "center" }}>
              <i className="anticon">
                <img
                  alt="tickets-page"
                  src={require("../../images/nav-icons/outline_local_activity_white_24dp.png")}
                  width={22}
                />
              </i>
              <span style={{ color: color }}>TICKETS</span>
            </span>
          }
          style={{
            backgroundColor: "transparent",
          }}
        >
          <Menu.Item
            key="/admin/tickets/all"
            onClick={
              this.state.width > 500
                ? () => {}
                : () => {
                    this.props.handleNavCollapse(true);
                  }
            }
          >
            <NavLink to="/admin/tickets/all" className="nav-link">
              Tickets
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key="/admin/tickets/update"
            onClick={
              this.state.width > 500
                ? () => {}
                : () => {
                    this.props.handleNavCollapse(true);
                  }
            }
          >
            <NavLink to="/admin/tickets/update" className="nav-link">
              Update Matches
            </NavLink>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="sub5"
          title={
            <span style={{ display: "flex", alignItems: "center" }}>
              <i className="anticon">
                <img
                  alt="website-page"
                  src={require("../../images/nav-icons/baseline_web_white_48dp.png")}
                  width={22}
                />
              </i>
              <span style={{ color: color }}>WEBSITE</span>
            </span>
          }
          style={{
            backgroundColor: "transparent",
          }}
        >
          <Menu.Item
            key="/admin/website/general"
            onClick={
              this.state.width > 500
                ? () => {}
                : () => {
                    this.props.handleNavCollapse(true);
                  }
            }
          >
            <NavLink to="/admin/website/general" className="nav-link">
              General Setup
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key="/admin/website/sections"
            onClick={
              this.state.width > 500
                ? () => {}
                : () => {
                    this.props.handleNavCollapse(true);
                  }
            }
          >
            <NavLink to="/admin/website/sections" className="nav-link">
              Sections
            </NavLink>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="sub6"
          title={
            <span style={{ display: "flex", alignItems: "center", gap: 3 }}>
              <i className="anticon">
                <img
                  alt="stadium-page"
                  src={require("../../images/nav-icons/stadium_black_24dp.svg")}
                  width={22}
                />
              </i>
              <span style={{ color: color }}>STADIUM</span>
              {this.state.expanded && <FeatureBadge type="new" />}
            </span>
          }
          style={{
            backgroundColor: "transparent",
          }}
        >
          <Menu.Item
            key="/admin/stadium/setup"
            onClick={
              this.state.width > 500
                ? () => {}
                : () => {
                    this.props.handleNavCollapse(true);
                  }
            }
          >
            <NavLink to="/admin/stadium/setup" className="nav-link">
              Setup
            </NavLink>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="sub7"
          title={
            <span style={{ display: "flex", alignItems: "center" }}>
              <i className="anticon">
                <img
                  alt="directory-page"
                  src={require("../../images/nav-icons/baseline_travel_explore_white_24dp.png")}
                  width={22}
                />
              </i>
              <span style={{ color: color }}>DIRECTORY</span>
            </span>
          }
          style={{
            backgroundColor: "transparent",
          }}
        >
          <Menu.Item
            key="/admin/directory/groupListing"
            onClick={
              this.state.width > 500
                ? () => {}
                : () => {
                    this.props.handleNavCollapse(true);
                  }
            }
          >
            <NavLink to="/admin/directory/groupListing" className="nav-link">
              Group Listing
            </NavLink>
          </Menu.Item>
        </SubMenu>
        <Menu.Item
          key="/admin/help"
          style={{
            display: "flex",
            alignItems: "center",
            height: "34px",
            backgroundColor: "transparent",
            margin: this.props.expanded ? "-4px 0px 10px" : 0,
            fontSize: 18,
          }}
          onClick={
            this.state.width > 500
              ? () => {}
              : () => {
                  this.props.handleNavCollapse(true);
                }
          }
        >
          <div
            style={{
              height: this.props.expanded ? 30 : 40,
            }}
          >
            <span
              onClick={() => {
                this.props.history.replace("/admin/help");
              }}
              style={{ display: "flex", alignItems: "center" }}
            >
              <i className="anticon">
                <img
                  alt="help-page"
                  src={require("../../images/nav-icons/outline_info_white_48dp.png")}
                  width={22}
                />
              </i>

              <span style={{ color: color }}>HELP</span>
            </span>
          </div>
        </Menu.Item>

        <SubMenu
          key="sub8"
          title={
            <span style={{ display: "flex", alignItems: "center" }}>
              <i className="anticon">
                <img
                  alt="settings-page"
                  src={require("../../images/nav-icons/baseline_settings_white_48dp.png")}
                  width={22}
                />
              </i>
              <span style={{ color: color }}>SETTINGS</span>
            </span>
          }
          style={{
            backgroundColor: "transparent",
          }}
        >
          <Menu.Item
            key="/admin/groupSettings"
            onClick={
              this.state.width > 500
                ? () => {}
                : () => {
                    this.props.handleNavCollapse(true);
                  }
            }
          >
            <NavLink to="/admin/groupSettings" className="nav-link">
              Design
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key="/admin/appSettings"
            onClick={
              this.state.width > 500
                ? () => {}
                : () => {
                    this.props.handleNavCollapse(true);
                  }
            }
          >
            <NavLink to="/admin/appSettings" className="nav-link">
              App
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key="/admin/paymentDetails"
            onClick={
              this.state.width > 500
                ? () => {}
                : () => {
                    this.props.handleNavCollapse(true);
                  }
            }
          >
            <NavLink to="/admin/paymentDetails" className="nav-link">
              Payments
            </NavLink>
          </Menu.Item>
        </SubMenu>
      </Menu>
    );
  }

  getClubadminMenu(color) {
    return (
      <Menu
        style={{
          backgroundColor: "transparent",
        }}
        mode="inline"
        defaultSelectedKeys={[window.location.pathname]}
        theme="dark"
        selectedKeys={[window.location.pathname]}
      >
        <SubMenu
          key="sub1"
          title={
            <span style={{ display: "flex", alignItems: "center" }}>
              <i className="anticon">
                <img
                  alt="groups-page"
                  src={require("../../images/nav-icons/baseline_people_white_48dp.png")}
                  width={22}
                />
              </i>
              <span className="nav-text" style={{ color: color }}>
                GROUPS
              </span>
            </span>
          }
          style={{
            backgroundColor: "transparent",
          }}
        >
          <Menu.Item
            key="/clubadmin/groups"
            onClick={
              this.state.width > 500
                ? () => {}
                : () => {
                    this.props.handleNavCollapse(true);
                  }
            }
          >
            <NavLink to="/clubadmin/groups" className="nav-link">
              Groups
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key="/clubadmin/members"
            onClick={
              this.state.width > 500
                ? () => {}
                : () => {
                    this.props.handleNavCollapse(true);
                  }
            }
          >
            <NavLink to="/clubadmin/members" className="nav-link">
              Members
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key="/clubadmin/membership"
            onClick={
              this.state.width > 500
                ? () => {}
                : () => {
                    this.props.handleNavCollapse(true);
                  }
            }
          >
            <NavLink to="/clubadmin/membership" className="nav-link">
              Card
            </NavLink>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="sub2"
          title={
            <span style={{ display: "flex", alignItems: "center" }}>
              <i className="anticon">
                <img
                  alt="content-page"
                  src={require("../../images/nav-icons/baseline_post_add_white_48dp.png")}
                  width={22}
                />
              </i>
              <span style={{ color: color }}>CONTENT</span>
            </span>
          }
          style={{
            backgroundColor: "transparent",
          }}
        >
          <Menu.Item
            key="/clubadmin/content"
            onClick={
              this.state.width > 500
                ? () => {}
                : () => {
                    this.props.handleNavCollapse(true);
                  }
            }
          >
            <NavLink to="/clubadmin/content" className="nav-link">
              News/Events
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key="/clubadmin/chants"
            onClick={
              this.state.width > 500
                ? () => {}
                : () => {
                    this.props.handleNavCollapse(true);
                  }
            }
          >
            <NavLink to="/clubadmin/chants" className="nav-link">
              Chants
            </NavLink>
          </Menu.Item>
        </SubMenu>
        <Menu.Item
          key="/clubadmin/gallery"
          style={{
            display: "flex",
            alignItems: "center",
            height: "34px",
            backgroundColor: "transparent",
            margin: this.props.expanded ? "-4px 0px 10px" : 0,
            fontSize: 18,
          }}
          onClick={
            this.state.width > 500
              ? () => {}
              : () => {
                  this.props.handleNavCollapse(true);
                }
          }
        >
          <div
            style={{
              height: this.props.expanded ? 30 : 40,
            }}
          >
            <span
              onClick={() => {
                this.props.history.replace("/clubadmin/gallery");
              }}
              style={{ display: "flex", alignItems: "center" }}
            >
              <i className="anticon">
                <img
                  alt="gallery-page"
                  src={require("../../images/nav-icons/photo_library_FILL0_wght400_GRAD0_opsz48.svg")}
                  width={22}
                />
              </i>

              <span style={{ color: color }}>GALLERY</span>
            </span>
          </div>
        </Menu.Item>
        <Menu.Item
          key="/clubadmin/Polls"
          style={{
            display: "flex",
            alignItems: "center",
            height: "34px",
            backgroundColor: "transparent",
            margin: this.props.expanded ? "-4px 0px 10px" : 0,
            fontSize: 18,
          }}
          onClick={
            this.state.width > 500
              ? () => {}
              : () => {
                  this.props.handleNavCollapse(true);
                }
          }
        >
          <div
            style={{
              height: this.props.expanded ? 30 : 40,
            }}
          >
            <span
              onClick={() => {
                this.props.history.replace("/clubadmin/Polls");
              }}
              style={{ display: "flex", alignItems: "center" }}
            >
              <i className="anticon">
                <img
                  alt="polls-page"
                  src={require("../../images/nav-icons/insert_chart_FILL0_wght400_GRAD0_opsz48.svg")}
                  width={22}
                />
              </i>

              <span style={{ color: color }}>POLLS</span>
            </span>
          </div>
        </Menu.Item>
        <Menu.Item
          key="/clubadmin/giveaways"
          style={{
            display: "flex",
            alignItems: "center",
            height: "34px",
            backgroundColor: "transparent",
            margin: this.props.expanded ? "-4px 0px 10px" : 0,
            fontSize: 18,
          }}
          onClick={
            this.state.width > 500
              ? () => {}
              : () => {
                  this.props.handleNavCollapse(true);
                }
          }
        >
          <div
            style={{
              height: this.props.expanded ? 30 : 40,
            }}
          >
            <span
              onClick={() => {
                this.props.history.replace("/clubadmin/giveaways");
              }}
              style={{ display: "flex", alignItems: "center" }}
            >
              <i className="anticon">
                <img
                  alt="giveaways-page"
                  src={require("../../images/nav-icons/redeem_FILL0_wght400_GRAD0_opsz48.svg")}
                  width={22}
                />
              </i>

              <span style={{ color: color }}>GIVEAWAYS</span>
            </span>
          </div>
        </Menu.Item>
        <Menu.Item
          key="/clubadmin/help"
          style={{
            display: "flex",
            alignItems: "center",
            height: "34px",
            backgroundColor: "transparent",
            margin: this.props.expanded ? "-4px 0px 10px" : 0,
            fontSize: 18,
          }}
          onClick={
            this.state.width > 500
              ? () => {}
              : () => {
                  this.props.handleNavCollapse(true);
                }
          }
        >
          <div
            style={{
              height: this.props.expanded ? 30 : 40,
            }}
          >
            <span
              onClick={() => {
                this.props.history.replace("/clubadmin/help");
              }}
              style={{ display: "flex", alignItems: "center" }}
            >
              <i className="anticon">
                <img
                  alt="help-page"
                  src={require("../../images/nav-icons/outline_info_white_48dp.png")}
                  width={22}
                />
              </i>

              <span style={{ color: color }}>HELP</span>
            </span>
          </div>
        </Menu.Item>
      </Menu>
    );
  }
}

const mapStateToProps = (state) => {
  let result = {};
  if (state.user.role === "admin") {
    result.adminData = state.adminData;
  }
  result.dashboard = state.adminData.dashboardType;
  result.user = state.user;
  return result;
};

export default connect(mapStateToProps)(SideNav);
